/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";

import bitcoin from '../../images/bitcoin.png'

import profilimg from '../../images/imgprofil/profilimg.png'
import tradinglogv2 from '../../images/imgprofil/tradinglogv2.png'
import cercleimg from '../../images/imgprofil/cercleimg.png'

import USD from '../../images/USD.png'
import totalenergies from '../../images/totalenergie.png'
import Ethereum from '../../images/Ethereumimg.png';
import { Link } from "react-router-dom";

import keyprofil from '../../images/imgprofil/keyprofil.png'
import userprof from '../../images/imgprofil/userprof.png'
import equipeprof from '../../images/imgprofil/equipeprof.png'
import BarChart from "../../components/chartgraphique/BarChartSimple";
import { useDispatch, useSelector } from 'react-redux';
import { getUserWithTeam,getclassementbyuser } from '../../Api/authApi.js';
import { toast } from 'react-toastify';


const Profil = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [classement, setclassement] = useState('');
  const [teamName, setTeamName] = useState('');
  const [nameuser, setNameuser] = useState('');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  // useEffect(() => {
  //   console.log(user)
  // }, []);

  useEffect(() => {
    const fetchUserWithTeam = async () => {
      try {
        const data = await getclassementbyuser(user.id);
        // setclassement(data);
        // console.log('setclassement',data)

      } catch (error) {
        console.error('Erreur lors de la récupération des informations de l\'utilisateur :', error.message);
      }
    };

    if (user.id) {
      fetchUserWithTeam();
    }
  }, [user.id]);
  useEffect(() => {
    const fetchUnameWithTeam = async () => {
      try {
        // Appel de la fonction pour récupérer les informations de l'utilisateur et de son équipe
        const data = await getUserWithTeam(user.id);
        console.log('data', data); // Afficher les données récupérées dans la console
  
        // Vérifier si les données contiennent des informations sur l'équipe
        if (data) {
          setTeamName(data.team_name); // Définir le nom de l'équipe
          // setNameuser(data.username); // Vous pouvez décommenter si nécessaire
        } else {
          console.error('Aucune donnée reçue');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations de l\'utilisateur :', error.message);
      }
    };
  
    // Vérifier si l'ID de l'utilisateur est défini avant de faire l'appel
    if (user.id) {
      fetchUnameWithTeam();
    }
  }, [user.id]);
  

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const getInitials = (name) => {
    return name.split(' ').map(word => word[0]).join('').toUpperCase();
}
  const data = [
    { imgSrc: totalenergies, Nombre: 59, Valeur: 3500, GP: -143 },
    { imgSrc: bitcoin, Nombre: 0.05, Valeur: 2800, GP: 435 },
    { imgSrc: USD, Nombre: 0, Valeur: 2000, GP: 50 },
    { imgSrc: Ethereum, Nombre: 0.85, Valeur: 1700, GP: -82 },

  ];

  return (
    <div className="bg-black w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">
    
      <div className="grid  lg:grid-cols-2  md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col items-center justify-center  gap-4 sm:px-10 md:px-16">
        <div className="lg:w-[98%] lg:border-gray-200 lg:border sm:border  md:border   rounded-lg w-[98%] lg:p-2 py-3 border-gray-200 shadow bg-balck text-white flex justify-center mx-auto  flex-col  gap-2 ">

          <div className=" w-[100%]   shadow ">
            <div className="relative w-[100%]">
              <img className="rounded-t-lg w-[100%] h-auto" src={profilimg} alt="" />
              <div className="absolute gap-12 inset-0 flex justify-center items-center ">
                <img className="object-scale-down  w-auto h-auto" src={tradinglogv2} alt="" />
                <div className="relative">
                  <img className="w-16 h-16 object-scale-down" src={cercleimg} alt="" />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <p className="text-black font-bold text-4xl font-tinos text-center">                                        {getInitials(user.username)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className=" p-2 md:p-3 lg:p-4">
              <p className="font-semibold lg:text-lg lg:py-2 py-1 text-start">Mes informations
              </p>
              <div className="flex flex-col gap-3 py-2 px-1">
                <div className="flex justify-between">
                  <span className="flex gap-2 w-1/2">
                    <img src={userprof} class="  w-6 h-6 placeholder-red-300" />
                    <p className="text-[14px] lg:text-[16px] flex items-center justify-center text-center">{user.username}</p>
                  </span>
                  <span className="flex w-1/2 gap-1">
                    <Link to='/modifie-nom-utilisateur' className="border p-1 w-full text-[9.5px] text-center rounded-sm items lg:text-[13.5px]"> <button >Modifier le nom d'utilisateur
                    </button></Link>
                  </span>
                </div>
                <div className="flex gap-3">
                  <span>
                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M2.038 5.61A2.01 2.01 0 0 0 2 6v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6c0-.12-.01-.238-.03-.352l-.866.65-7.89 6.032a2 2 0 0 1-2.429 0L2.884 6.288l-.846-.677Z" />
                      <path d="M20.677 4.117A1.996 1.996 0 0 0 20 4H4c-.225 0-.44.037-.642.105l.758.607L12 10.742 19.9 4.7l.777-.583Z" />
                    </svg>

                  </span>
                  <p className="text-[14px] flex items-center justify-center text-center lg:text-[16px]">{user.email}</p>

                </div>
                <div className="flex justify-between">
                  <span className="flex gap-3">
                    <img src={keyprofil} class=" w-6 h-6" />                    <p className="text-[14px] flex  items-center justify-center text-center lg:text-[16px]">"Mot de passe"</p>

                  </span>
                  <span className="flex w-1/2 ">
                    <Link to='/modifie-mot-de-passe' className="border p-1 text-[11px] rounded-sm text-center w-full lg:text-[14px]">
                      <button >Modifier le mot de passe

                      </button></Link>
                  </span>
                </div>
                <div className="flex  justify-between">
                  <span className="flex gap-2">
                    <img src={equipeprof} class="  w-6 h-6 " />
                    <p className="text-[14px] flex items-center justify-center text-center lg:text-[16px]">{teamName}</p>

                  </span>
                  <span className="flex w-1/2 gap-4">
                    <Link to='/modifie-nom-equipe' className="border p-1 text-[11px] rounded-sm items w-full text-center lg:text-[14px]">
                      <button >Changer d'équipe

                      </button></Link>
                  </span>

                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="lg:w-[98%] border-t lg:border-gray-200 lg:border sm:border  md:borde lg:rounded-lg w-[98%] lg:py-10 py-4  border-gray-200 shadow bg-balck text-white flex justify-center mx-auto  flex-col  gap-2 ">
          <div className=" w-[100%] shadow ">
            <div className="p-3">
              <p className=" font-semibold lg:text-lg lg:py-2 py-1 text-start">Mes statistiques</p>
              <BarChart />

            </div>
          </div>

        </div>




      </div>


    </div>

  )
};
export default Profil;
