// // src/BarChart.js
// import React from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const BarChart = () => {
//     const labels = ['Semaine 1', 'Semaine 2', 'Semaine 3', 'Semaine 4'];
//     const dataValues = [10000, 9000, 12000, 8000,]; // Valeurs augmentant de 2000 à 12000

//     const data = {
//         labels: labels,
//         datasets: [
//             {
//                 label: 'Performance',
//                 backgroundColor: [
//                     '#7ed957',  // Couleur pour Semaine 1
//                     '#ff3131', // Couleur pour Semaine 2
//                     '#7ed957',  // Couleur pour Semaine 1
//                     '#ff3131', //
//                 ],
            
//                 borderWidth: 1,
//                 data: dataValues,
//             },
//         ],
//     };

//     const options = {
//         plugins: {
//             title: {
//                 display: true,
//             },
//             legend: {
//                 display: false, 
//             },
//             roundedBars: true, // Activer le plugin des barres arrondies

//         },
//         responsive: true,
//         maintainAspectRatio: false,
//         scales: {
//             x: {
//                 stacked: false,
//             },
//             y: {
//                 stacked: false,
//                 suggestedMax: 12000,
//                 // title: {
//                 //     display: true,
//                 // },
//             },
//         },
//     };

//     return (
//         <div style={{ width: '100%', height: '60vh' }}>
//             <Bar data={data} options={options} />
//         </div>
//     );
// };

// export default BarChart;
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { getUserWithTeam,getclassementbyuser } from '../../../Api/authApi.js';
import { useDispatch, useSelector } from 'react-redux';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ userId }) => {
    const [labels, setLabels] = useState([]);
    const [dataValues, setDataValues] = useState([]);
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response =  await getclassementbyuser(user.id);
                const weeklyTotals = response.weekly_totals;
                const labels = Object.keys(weeklyTotals);
                const dataValues = labels.map(week => parseFloat(weeklyTotals[week].total) || 0);

                setLabels(labels);
                // setDataValues(dataValues);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error.message);
            }
        };

        fetchData();
    }, [userId]);

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Performance',
                backgroundColor: [
                    '#7ed957',  // Couleur pour Semaine 1
                    '#ff3131',  // Couleur pour Semaine 2
                    '#7ed957',  // Couleur pour Semaine 3
                    '#ff3131',  // Couleur pour Semaine 4
                ],
                borderWidth: 1,
                data: dataValues,
            },
        ],
    };

    const options = {
        plugins: {
            title: {
                display: true,
            },
            legend: {
                display: false, 
            },
            roundedBars: true,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: false,
            },
            y: {
                stacked: false,
                suggestedMax: 1200,
            },
        },
    };

    return (
        <div style={{ width: '100%', height: '60vh' }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default BarChart;
