import React, { useState, useEffect } from "react";
import settingg from '../../images/settingg.png'
import logonavbar from '../../images/logonavbar.png'
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { logout } from "../../redux/authSlice";
import { funcGetTransactionsGroupedByMarket, getArticles, getCurrentVideo, getDefis, getVideos } from '../../Api/authApi.js';
import { toast, ToastContainer } from 'react-toastify';

const Drawer = ({ toggleDrawer, isDrawerOpen }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [videos, setVideos] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [error, setError] = useState(null);

    const handleLinkClick = (path) => {
        navigate(path);
        toggleDrawer(); // Ferme le drawer
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/connexion'); // Navigate to the login page
    };
    const handleLogoClick = () => {
        navigate('/accueil'); // Redirige vers /accueil
    };
    // useEffect(() => {
    //     const fetchVideos = async () => {
    //         try {
    //             const response = await getVideos();
    //             setVideos(response.data);
    //             // console.log(response)
    //         } catch (err) {
    //             setError(err.message);
    //         }
    //     };

    //     fetchVideos();
    // }, []);


    // useEffect(() => {
    //     const fetchVideos = async () => {
    //         try {
    //             const response = await getCurrentVideo();
    //             const videosWithStatus = response.data.map(video => ({
    //                 ...video,
    //                 hasBeenShown: false,  // Ajouter une propriété pour suivre l'affichage
    //             }));
    //             setVideos(videosWithStatus);
    //         } catch (err) {
    //             console.error('Erreur lors de la récupération des vidéos:', err.message);
    //         }
    //     };

    //     fetchVideos();
    // }, []);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         const now = new Date();

    //         setVideos(prevVideos => prevVideos.map(video => {
    //             const videoDateTime = new Date(`${video.display_date}T${video.display_time}`);

    //             // Vérifier si l'heure et la minute actuelles correspondent exactement à celles de la vidéo
    //             if (
    //                 now.getHours() === videoDateTime.getHours() &&
    //                 now.getMinutes() === videoDateTime.getMinutes()
    //             ) {
    //                 const videoShown = localStorage.getItem(`video_${video.id}`);
    //                 if (!videoShown) {
    //                     showToastWithVideo(video);
    //                     localStorage.setItem(`video_${video.id}`, 'true'); // Marquer la vidéo comme affichée
    //                     return { ...video, hasBeenShown: true }; // Marquer la vidéo comme affichée dans l'état local aussi
    //                 }
    //             }
    //             return video;
    //         }));
    //     }, 1000); // Vérifier toutes les secondes

    //     return () => clearInterval(interval); // Nettoyage de l'intervalle lors du démontage du composant
    // }, [videos]);
    // const showToastWithVideo = (video) => {
    //     toast(
    //         <div>
    //             <h4>{video.title}</h4>
    //             <video width="300" height="120" controls>
    //                 <source src={`https://tradingseries.fr/admin/public/storage/videos/${video.video_path.split('/').pop()}`} type="video/mp4" />
    //                 Votre navigateur ne supporte pas la lecture de vidéos.
    //             </video>
    //         </div>,
    //         {
    //             autoClose: false,
    //             draggable: false,
    //             closeOnClick: false,
    //             position: "bottom-center", // Remplacez par une chaîne de caractères correcte
    //         }
    //     );
    // };
    return (
        <nav className="fixed top-0 left-0 w-full bg-black text-white  px-2 z-50 ">
            <div className=" flex justify-between py-4 pr-1   ">
                <img src={logonavbar} className="" width={225} height={220} alt="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }} />
                <button className="" onClick={toggleDrawer}>

                    <img src={settingg} class=" w-auto h-auto" />
                </button>


            </div>
            {isDrawerOpen && (
                <div className="fixed flex justify-center top-0 right-0 w-80 h-full shadow-lg  bg-black text-white border border-white rounded-md  z-50">
                    <span className=" text-yellow-700 absolute right-5 top-4" onClick={toggleDrawer}>
                        <svg className="w-9 h-9 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fillRule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clipRule="evenodd" />
                        </svg>


                    </span>
                    <div className="flex flex-col space-y-4 mt-20 relative">
                        <button onClick={() => handleLinkClick('/accueil')} className="text-white rounded-md font-bold w-[100%] py-2.5 flex gap-4 pl-4 pr-4">
                            Accueil
                        </button>
                        <button onClick={() => handleLinkClick('/PorteFeuille')} className="text-white rounded-md font-bold w-[100%] py-2.5 flex gap-4 pl-4 pr-4">
                            Portefeuille
                        </button>
                        <button onClick={() => handleLinkClick('/portefeuille_menu')} className="text-white rounded-md font-bold w-[100%] py-2.5 flex gap-4 pl-4 pr-4">
                            Marchés
                        </button>
                        <button onClick={() => handleLinkClick('/classement')} className="text-white rounded-md font-bold w-[100%] py-2.5 flex gap-4 pl-4 pr-4">
                            Classement
                        </button>
                        <button onClick={() => handleLinkClick('/profil')} className="text-white rounded-md font-bold w-[100%] py-2.5 flex gap-4 pl-4 pr-4">
                            Profil
                        </button>
                        <button onClick={handleLogout} className="text-white rounded-md font-bold w-[100%] py-2.5 flex gap-4 pl-4 pr-4">
                            Déconnexion
                        </button>
                    </div>
                </div>
            )}
        </nav>
    )
}

export default Drawer