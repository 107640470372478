/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import ReactFlagsSelect from "react-flags-select";

import bitcoin from '../../images/bitcoin.png'

import USD from '../../images/USD.png'
import Ethereum from '../../images/Ethereumimg.png';
import { Link } from "react-router-dom";
import ProgressiveLineChart from "../../components/chartgraphique/progressiveLineChart";
// import TradingChart from "../../components/chartgraphique/CandlestickChart";
import Carousel from "../../components/Carousel";

import { getMarches, getDataForCurrentMinutes, postAchat, getImagesByMarketType, getMarchesByType, getAvailableCountriesByType } from '../../Api/authApi.js';
import axios from 'axios'; // Import Axios
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';




const PortefeuilleMenu = () => {
  const [selectedTime, setSelectedTime] = useState('1min');
  const navigate = useNavigate(); // Ajouter cette ligne
  const userId = useSelector((state) => state.auth.user.id);
  // console.log('datauser',userId)

  const timeOptions = [
    { key: '1min', function: 'getDataForCurrentDay' },
    { key: '5min', function: 'getDataForLast5Minutes' },
    { key: '15min', function: 'getDataForLast15Minutes' },
    { key: '30min', function: 'getDataForLast30Minutes' },
    { key: '1h', function: 'getDataForLastHour' },
    { key: '4h', function: 'getDataForLast4Hours' },
    { key: '1d', function: 'getDataForLastDay' },
    { key: '1w', function: 'getDataForLastWeek' }
  ];

  const handleSelectChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const cardData = [
    { id: 1, img: Ethereum, title: 'Titre 1', value: 'Valeur 1' },
    { id: 2, img: USD, title: 'Titre 2', value: 'Valeur 2' },
    { id: 3, img: Ethereum, title: 'Titre 3', value: 'Valeur 3' },
    { id: 4, img: bitcoin, title: 'Titre 1', value: 'Valeur 1' },
    { id: 5, img: USD, title: 'Titre 2', value: 'Valeur 2' },
    { id: 6, img: Ethereum, title: 'Titre 3', value: 'Valeur 3' },
  ];
  const [startIndex, setStartIndex] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState(getCardsPerPage());

  function getCardsPerPage() {
    if (window.innerWidth < 640) {
      return 1;
    } else {
      return 3;
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setCardsPerPage(getCardsPerPage());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNext = () => {
    setStartIndex((prevIndex) => (prevIndex + cardsPerPage) % cardData.length);
  };

  const handlePrevious = () => {
    setStartIndex((prevIndex) => (prevIndex - cardsPerPage + cardData.length) % cardData.length);
  };

  const currentCards = cardData.slice(startIndex, startIndex + cardsPerPage);
  const [marches, setMarches] = useState([]);
  const [marchesid, setmarchesid] = useState(5);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMarche, setSelectedMarche] = useState(null);
  const [marketData, setMarketData] = useState({}); // Ajouter l'état pour les données du marché
  const [marketImages, setMarketImages] = useState({});
  const { type } = useParams(); // Récupérer le paramètre "type" depuis l'URL
  const [countries, setCountries] = useState([]);

  const handleClick = (marche) => {
    setSelectedMarche(marche);
    setmarchesid(marche.id);
    // console.log(marchesid)
  };
  // useEffect(() => {
  //   const fetchMarches = async () => {
  //     try {
  //       const data = await getMarches();
  //       setMarches(data);
  //       if (data.length > 0) {
  //         setSelectedMarche(data[0]);
  //         setmarchesid(data[0].id); // Initialisez l'ID du premier marché
  //       }
  //     } catch (err) {
  //       setError(err.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchMarches();
  // }, []);
  useEffect(() => {
    const fetchMarches = async () => {
      try {
        setLoading(true); // Assurez-vous que le chargement commence
        const data = await getMarches(userId); // Appel à l'API avec le user_id
        setMarches(data);
        if (data.length > 0) {
          setSelectedMarche(data[0]); // Sélectionnez le premier marché
          setmarchesid(data[0].id); // Initialisez l'ID du premier marché
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchMarches();
    }
  }, [userId]); 

  useEffect(() => {
    const fetchMarketData = async () => {
      if (marches.length > 0) {
        try {
          const dataPromises = marches.map(marche =>
            getDataForCurrentMinutes(marche.id).then(data => ({
              id: marche.id,
              date: data.date,
              day: data.day,
              hour: data.hour,
              minute: data.minute,
              last_value: data.last_value,
              difference: data.difference,
              value: parseFloat(data.value).toFixed(2) // Arrondir la valeur ici
            }))
          );
          const results = await Promise.all(dataPromises);
          const marketDataMap = results.reduce((acc, { id, date, day, hour, minute, value, difference }) => {
            acc[id] = {
              date, day, hour, minute, value, difference
            };
            return acc;
          }, {});
          setMarketData(marketDataMap);
          console.log('marketDataMap', marketDataMap);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMarketData();
    const intervalId = setInterval(fetchMarketData, 60000); // 60000ms = 1 minute

    return () => clearInterval(intervalId); // Nettoyage de l'intervalle
  }, [marches]);

  useEffect(() => {
    const fetchMarketType = async () => {
      try {
        const response = await getImagesByMarketType();
        setMarketImages(response);
        // console.log(response)
      } catch (err) {
        setError(err.message);
      }
    };

    fetchMarketType();
  }, []);

  useEffect(() => {
    const fetchMarkets = async () => {
      try {
        const markets = await getMarchesByType('Actions');
        // console.log(markets);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchMarkets();
  }, []);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`https://tradingseries.fr/admin/public/api/marches/countries`, {
          params: { type }
        });
        setCountries(response.data);
        console.log(response.data);
      } catch (error) {
        setError(error.message);
        console.error(error.message);
      }
    };

    fetchCountries();
  }, [type]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const handleAchat = async () => {
    const { id, titre, icon, type } = selectedMarche;
    let { value, date, hour, difference } = marketData[id] || {};
    const userId = user.id;
    console.log('marketData[id]', marketData)

    // Vérifier que l'heure est correctement formatée
    if (!hour || typeof hour !== 'string' || hour.split(':').length !== 2) {
      console.error('Heure invalide:', hour);
      alert('Erreur: L\'heure est invalide.');
      return;
    }

    // Extraire les heures et minutes depuis la chaîne de caractères hour
    const [hourPart, minutePart] = hour.split(':').map(Number);

    if (isNaN(hourPart) || isNaN(minutePart)) {
      console.error('Heure ou minute invalide:', { hourPart, minutePart });
      alert('Erreur: L\'heure ou la minute est invalide.');
      return;
    }

    // Arrondir la valeur à deux décimales
    value = parseFloat(value).toFixed(2);

    const formattedTime = `${String(hourPart).padStart(2, '0')}:${String(minutePart).padStart(2, '0')}`;

    // Construire l'objet Date à partir des informations de date, heure et minute
    const dateObj = new Date(date);

    if (isNaN(dateObj.getTime())) {
      console.error('Date invalide:', date);
      alert('Erreur: La date est invalide.');
      return;
    }

    dateObj.setHours(hourPart + 1); // Ajuster l'heure pour le fuseau horaire GMT+1
    dateObj.setMinutes(minutePart);
    dateObj.setSeconds(0);
    // console.log('dateObj', dateObj);

    // Formater la date dans le format 'YYYY-MM-DD HH:MM:SS'
    const formattedDate = dateObj.toISOString().slice(0, 19).replace('T', ' ');
    // console.log('Date formattée:', formattedDate);

    try {
      const response = await postAchat(userId, id, value, type);
      // console.log('Achat effectué avec succès:', response);
      navigate('/portefeuille_achat', { state: { id, titre, icon, value, formattedTime, difference, type } });
    } catch (error) {
      console.error('Erreur lors de l\'achat:', error);
      alert('Une erreur est survenue lors de l\'achat. Veuillez réessayer.');
    }
  };

  const handleVente = async () => {
    const { id, titre, icon, type } = selectedMarche; // Récupération du type de marché ici
    let { value, date, hour, difference } = marketData[id] || {};
    const userId = user.id;

    // Vérifier que l'heure est correctement formatée
    if (!hour || typeof hour !== 'string' || hour.split(':').length !== 2) {
      console.error('Heure invalide:', hour);
      // alert('Erreur: L\'heure est invalide.');
      return;
    }

    // Extraire les heures et minutes depuis la chaîne de caractères hour
    const [hourPart, minutePart] = hour.split(':').map(Number);

    if (isNaN(hourPart) || isNaN(minutePart)) {
      console.error('Heure ou minute invalide:', { hourPart, minutePart });
      // alert('Erreur: L\'heure ou la minute est invalide.');
      return;
    }

    // Arrondir la valeur à deux décimales
    value = parseFloat(value).toFixed(2);

    const formattedTime = `${String(hourPart).padStart(2, '0')}:${String(minutePart).padStart(2, '0')}`;

    // Construire l'objet Date à partir des informations de date, heure et minute
    const dateObj = new Date(date);

    if (isNaN(dateObj.getTime())) {
      console.error('Date invalide:', date);
      alert('Erreur: La date est invalide.');
      return;
    }

    dateObj.setHours(hourPart + 1); // Ajuster l'heure pour le fuseau horaire GMT+1
    dateObj.setMinutes(minutePart);
    dateObj.setSeconds(0);
    // console.log('dateObj', dateObj);

    // Formater la date dans le format 'YYYY-MM-DD HH:MM:SS'
    const formattedDate = dateObj.toISOString().slice(0, 19).replace('T', ' ');
    // console.log('Date formattée:', formattedDate);

    try {
      const response = await postAchat(userId, id, value, type); // Inclure le type de marché dans la requête
      // console.log('Achat effectué avec succès:', response);
      navigate('/portefeuille_vente', { state: { id, titre, icon, value, formattedTime, difference, type } });
    } catch (error) {
      console.error('Erreur lors de l\'achat:', error);
      alert('Une erreur est survenue lors de l\'achat. Veuillez réessayer.');
    }
  };



  if (!selectedMarche) return null;

  const { id, icon, titre } = selectedMarche;
  const { hour, minute, value, date, difference } = marketData[id] || {};

  const queryParams = new URLSearchParams({
    id,
    logo: encodeURIComponent(icon),
    titre: encodeURIComponent(titre),
    hour: hour || 'N/A',
    minute: minute || 'N/A',
    value: value || 'N/A',
    date: date || 'N/A'
  }).toString();
  // console.log(queryParams)

  const latestValue = marketData && marketData.values && marketData.values.length > 0
    ? marketData.values[0].value
    : 'N/A';

  const marketPageMap = {
    "Actions": "/portefeuille_actions/Actions",
    "Matiére premiéres": "/portefeuille_actions/Matiére premiéres",
    "Crypto-monnaies": "/portefeuille_actions/Crypto-monnaies",
    "Divies": "/portefeuille_actions/Divies"
  };


  return (
    <div className="bg-black w-full relative overflow-auto h-[100%] lg:pb-auto pb-14 pt-6">

      <div className="grid  lg:grid-cols-1   md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col  gap-4 sm:px-10 md:px-16">
        <div className="lg:w-[98%] w-[98%] p-2 lg:border border-gray-500 lg:rounded-lg  sm:border md:border  xl:border 2xl:border shadow bg-balck text-white flex justify-center mx-auto  py-1 lg:py-4  flex-col border-b  ">
          <div className=" flex  justify-between pt-2">
            <p className="flex justify-start text-left font-medium px-2 text-sm ">Marchés financiers</p>
            <button className=" bg-black text-white flex text-xs  border-gray-500 border gap-x-4 pr-6 pl-4 items-center ">
              <span className="">
                <svg class="w-4 h-4 text-yellow-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                </svg>

              </span>
              <span className=" font-medium text-xs lg:text-sm">Favoris</span>

            </button>
          </div>
          <div>
            <div className="relative container mx-auto p-4 ">
              <div className="absolute inset-y-1/2 left-0 flex items-center justify-center transform -translate-y-1/2">
                <button
                  onClick={handlePrevious}
                  className="bg-gray-800 text-white p-1.5 rounded-full shadow-md hover:bg-gray-700 transition duration-300"
                >
                  <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13" />
                  </svg>
                </button>
              </div>
              <div className="absolute inset-y-1/2 right-0 flex items-center justify-center transform -translate-y-1/2">
                <button
                  onClick={handleNext}
                  className="bg-gray-800 text-white p-1.5 rounded-full shadow-md hover:bg-gray-700 transition duration-300"
                >

                  <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1" />
                  </svg>
                </button>
              </div>
              <div className={`flex gap-2 px-3 ${cardsPerPage === 2 ? 'sm:flex-row' : 'md:flex-row'}`}>
                {marches.map(marche => (
                  <div key={marche.id} onClick={() => handleClick(marche)} className={` cursor-pointer  bg-slate-900 rounded-2xl  px-1   items-center p-2 flex gap-2 flex-row  shadow-md ${cardsPerPage === 2 ? 'w-full sm:w-[100%]' : 'w-full md:w-1/3'}`}>
                    <img
                      src={`https://tradingseries.fr/admin/storage/app/public/${marche.icon}`} // URL des images
                      alt={marche.titre} className="w-11 h-11  rounded-full mb-2" />
                    <div className="flex flex-col  ">
                      <p className="text-[10px] font-bold text-amber-300 ">{marche.type}</p>
                      <p className="text-[10px] font-semibold">{marche.titre}</p>


                      <p className="text-[9px] font-semibold">
                        {marketData[marche.id]
                          ? `${marketData[marche.id].value}€`
                          : ''}
                      </p>
                      <p className="text-[8px] font-semibold">
                        {marketData[marche.id]
                          ? `  ${marketData[marche.id].date}`
                          : ''}
                      </p>
                      <p className="text-[8px] font-semibold">
                        {marketData[marche.id]
                          ? `  ${marketData[marche.id].hour}`
                          : ''}
                      </p>

                    </div>
                  </div>
                ))}
              </div>
            </div>

          </div>
          {selectedMarche && (

            <div className=" flex  justify-between gap-1 items-center py-4">
              <img src={`https://tradingseries.fr/admin/storage/app/public/${selectedMarche.icon}`} className="w-9 "></img>
              <p className="text-[10px]">{selectedMarche.titre}</p>
              <div className=" flex gap-2">


                <button className="py-1 px-3 text-[10px] bg-[#678f2c] rounded-sm text-black font-medium" onClick={handleAchat}
                >
                  ACHAT
                </button>
                <button className="py-1 px-3 text-[10px] bg-[#97271d] rounded-sm text-black font-medium" onClick={handleVente}
                >
                  VENTE
                </button>




              </div>
            </div>
          )}
          <div className="w-[100%]">
            {/* <ProgressiveLineChart  marcheId={6} /> */}
            <ProgressiveLineChart selectedTime={selectedTime} userId={userId} marcheId={marchesid} />


          </div>

          <div className=" flex  justify-between px-3 gap-1 items-center lg:py-4">
            <div className="w-auto">

              <select
                id="time-select"
                value={selectedTime}
                onChange={handleSelectChange}
                className="mt-1 block w-full pl-3 py-2 border-gray-300 focus:outline-none focus:border-indigo-500 sm:text-sm text-xs rounded-md text-white bg-black"
              >
                {timeOptions.map((time, index) => (
                  <option key={index} value={time.key}>
                    {time.key}
                  </option>
                ))}
              </select>

            </div>
            <Link to={`/portefeuille_detail/${marchesid}`} className="text-[11px] font-medium lg:text-sm "><span class="slot-bYDQcOkp"><span role="img" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M12 7h-.75V4h-1.5v3H9a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h.75v3h1.5v-3H12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM9.5 19.5v-11h2v11h-2Zm8-3v-5h2v5h-2Zm.24-6.5H17a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h.75v3h1.5v-3H20a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-.76V7h-1.5v3Z"></path></svg></span></span></Link>

          </div>


        </div>
        <div className="lg:w-[98%] w-[98%] px-2  sm:border md:border lg:border xl:border 2xl:border border-gray-200 lg:rounded-lg shadow bg-balck text-white flex justify-center mx-auto lg:py-4   flex-col  ">


          <div className="lg:w-[98%] w-[98%]  sm:border md:border lg:border xl:border 2xl:border border-gray-200 lg:rounded-lg shadow bg-black text-white flex justify-center mx-auto lg:py-4 flex-col">
            <div className="w-auto flex justify-between py-4">
              <Link to="/portefeuille_actions" className="flex items-center text-[13px] font-medium">

              </Link>
              <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-3 h-3 text-gray-300 "
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block  w-40 ps-10 text-sm text-gray-300 border border-gray-400 rounded-xs bg-black "
                  placeholder="Recherche"
                  required=""
                />

              </div>

            </div>
            {Object.entries(marketImages).map(([marketType, images]) => (
              <div key={marketType} className="">
                <div className="w-auto flex items-center py-1 mt-2">
                  <span><svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7" />
                  </svg>
                  </span>
                  {/* <p className="text-sm font-semibold ">{marketType}</p> */}
                  <Link to={marketPageMap[marketType]} className=" cursor-pointer text-sm font-semibold ">
                    {marketType}
                  </Link>
                </div>
                <Carousel images={images.map(image => `https://tradingseries.fr/admin/storage/app/public/${image.image_path}`)} />
              </div>
            ))}
          </div>

        </div>
      </div>

    </div>

  )
};
export default PortefeuilleMenu;
