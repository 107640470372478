/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import ReactFlagsSelect from "react-flags-select";
import * as d3 from 'd3';  // Utiliser d3 pour charger et parser les données CSV
import bitcoin from '../../images/bitcoin.png'
import totalenergielogo from '../../images/imgprof/totalenergielogo.png'
import francelogo from '../../images/drapeaux/francedrapeau.png'
import USD from '../../images/USD.png'
import Ethereum from '../../images/Ethereumimg.png';
import StockChart from "../../components/chartgraphique/StockChart";
import { useParams } from 'react-router-dom';
import {
    getMarches, getDataForCurrentMinutes, postAchat, getImagesByMarketType, getMarchesByType, getDatachartjb1m,
    getDataForCurrentAndLast10Minutes,

    getDataForCurrentAndLast30Minutes,
    getDataForCurrentAndLastHour,
    getDataForLast4Hours,
    getDataForCurrentAndLastDay,
    getDataForLastWeek,
} from '../../Api/authApi.js';
import axios from 'axios'; // Import Axios
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';


const PorteFeuilleDetail = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const navigate = useNavigate(); // Ajouter cette ligne
    const [selectedTime, setSelectedTime] = useState('10m');
    const userId = useSelector((state) => state.auth.user.id);

    const timeOptions = [
        { key: '1m', function: getDatachartjb1m },
        { key: '10m', function: getDataForCurrentAndLast10Minutes },
        { key: '30m', function: getDataForCurrentAndLast30Minutes },
        { key: '1h', function: getDataForCurrentAndLastHour },
        { key: '1j', function: getDataForCurrentAndLastDay },
        // { key: '1w', label: '1W', function: getDataForLastWeek },
    ];


    const cardData = [
        { id: 1, img: Ethereum, title: 'Titre 1', value: 'Valeur 1' },
        { id: 2, img: USD, title: 'Titre 2', value: 'Valeur 2' },
        { id: 3, img: Ethereum, title: 'Titre 3', value: 'Valeur 3' },
        { id: 4, img: bitcoin, title: 'Titre 1', value: 'Valeur 1' },
        { id: 5, img: USD, title: 'Titre 2', value: 'Valeur 2' },
        { id: 6, img: Ethereum, title: 'Titre 3', value: 'Valeur 3' },
    ];
    const [startIndex, setStartIndex] = useState(0);
    const [cardsPerPage, setCardsPerPage] = useState(getCardsPerPage());

    function getCardsPerPage() {
        if (window.innerWidth < 640) {
            return 1;
        } else {
            return 3;
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setCardsPerPage(getCardsPerPage());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleNext = () => {
        setStartIndex((prevIndex) => (prevIndex + cardsPerPage) % cardData.length);
    };

    const handlePrevious = () => {
        setStartIndex((prevIndex) => (prevIndex - cardsPerPage + cardData.length) % cardData.length);
    };

    const currentCards = cardData.slice(startIndex, startIndex + cardsPerPage);
    const [marches, setMarches] = useState([]);
    const [marchesid, setmarchesid] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedMarche, setSelectedMarche] = useState(null);
    const [marketData, setMarketData] = useState({}); // Ajouter l'état pour les données du marché
    const [marketImages, setMarketImages] = useState({});
    const { type } = useParams(); // Récupérer le paramètre "type" depuis l'URL
    const [countries, setCountries] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [data, setData] = useState(null); // État pour stocker les données récupérées


    const handleButtonClick = async (timeOption) => {

        setLoading(true);
        setError(null);
        setData(null); // Réinitialise les données pendant le chargement

        try {
            const result = await timeOption.function(marchesid); // Appelle la fonction associée
            setData(result); // Met à jour les données
            console.log('result', result)
            setSelectedPeriod(timeOption.key); // Met à jour la période sélectionnée
        } catch (error) {
            setError(error.message); // Capture et stocke l'erreur
        } finally {
            setLoading(false); // Arrête l'état de chargement
        }
    };

    const handleClick = (marche) => {
        setSelectedMarche(marche);
        setmarchesid(marche.id);
        // console.log(marchesid)
    };

    useEffect(() => {
        const fetchMarches = async () => {
          try {
            setLoading(true); // Assurez-vous que le chargement commence
            const data = await getMarches(userId); // Appel à l'API avec le user_id
            setMarches(data);
            if (data.length > 0) {
              setSelectedMarche(data[0]); // Sélectionnez le premier marché
              setmarchesid(data[0].id); // Initialisez l'ID du premier marché
            }
          } catch (err) {
            setError(err.message);
          } finally {
            setLoading(false);
          }
        };
    
        if (userId) {
          fetchMarches();
        }
      }, [userId]); 

    useEffect(() => {
        const fetchMarketData = async () => {
            if (marches.length > 0) {
                try {
                    const dataPromises = marches.map(marche =>
                        getDataForCurrentMinutes(marche.id).then(data => ({
                            id: marche.id,
                            date: data.date,
                            day: data.day,
                            hour: data.hour,
                            minute: data.minute,
                            last_value: data.last_value,
                            difference: data.difference,
                            value: parseFloat(data.value).toFixed(2) // Arrondir la valeur ici
                        }))
                    );
                    const results = await Promise.all(dataPromises);
                    const marketDataMap = results.reduce((acc, { id, date, day, hour, minute, value, difference }) => {
                        acc[id] = {
                            date, day, hour, minute, value, difference
                        };
                        return acc;
                    }, {});
                    setMarketData(marketDataMap);
                    // console.log('marketDataMap', marketDataMap);
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchMarketData();
        const intervalId = setInterval(fetchMarketData, 60000); // 60000ms = 1 minute

        return () => clearInterval(intervalId); // Nettoyage de l'intervalle
    }, [marches]);

    useEffect(() => {
        const fetchMarketType = async () => {
            try {
                const response = await getImagesByMarketType();
                setMarketImages(response);
                // console.log(response)
            } catch (err) {
                setError(err.message);
            }
        };

        fetchMarketType();
    }, []);

    useEffect(() => {
        const fetchMarkets = async () => {
            try {
                const markets = await getMarchesByType('Actions');
                // console.log(markets);
            } catch (error) {
                console.error(error.message);
            }
        };

        fetchMarkets();
    }, []);
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`https://tradingseries.fr/admin/public/api/marches/countries`, {
                    params: { type }
                });
                setCountries(response.data);
                console.log(response.data);
            } catch (error) {
                setError(error.message);
                console.error(error.message);
            }
        };

        fetchCountries();
    }, [type]);

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const handleAchat = async () => {
        const { id, titre, icon, type } = selectedMarche;
        let { value, date, hour, difference } = marketData[id] || {};
        const userId = user.id;

        // Vérifier que l'heure est correctement formatée
        if (!hour || typeof hour !== 'string' || hour.split(':').length !== 2) {
            console.error('Heure invalide:', hour);
            alert('Erreur: L\'heure est invalide.');
            return;
        }

        // Extraire les heures et minutes depuis la chaîne de caractères hour
        const [hourPart, minutePart] = hour.split(':').map(Number);

        if (isNaN(hourPart) || isNaN(minutePart)) {
            console.error('Heure ou minute invalide:', { hourPart, minutePart });
            alert('Erreur: L\'heure ou la minute est invalide.');
            return;
        }

        // Arrondir la valeur à deux décimales
        value = parseFloat(value).toFixed(2);

        const formattedTime = `${String(hourPart).padStart(2, '0')}:${String(minutePart).padStart(2, '0')}`;

        // Construire l'objet Date à partir des informations de date, heure et minute
        const dateObj = new Date(date);

        if (isNaN(dateObj.getTime())) {
            console.error('Date invalide:', date);
            alert('Erreur: La date est invalide.');
            return;
        }

        dateObj.setHours(hourPart + 1); // Ajuster l'heure pour le fuseau horaire GMT+1
        dateObj.setMinutes(minutePart);
        dateObj.setSeconds(0);
        // console.log('dateObj', dateObj);

        // Formater la date dans le format 'YYYY-MM-DD HH:MM:SS'
        const formattedDate = dateObj.toISOString().slice(0, 19).replace('T', ' ');
        // console.log('Date formattée:', formattedDate);

        try {
            const response = await postAchat(userId, id, value, type);
            // console.log('Achat effectué avec succès:', response);
            navigate('/portefeuille_achat', { state: { id, titre, icon, value, formattedTime, difference, type } });
        } catch (error) {
            console.error('Erreur lors de l\'achat:', error);
            alert('Une erreur est survenue lors de l\'achat. Veuillez réessayer.');
        }
    };

    const handleVente = async () => {
        const { id, titre, icon, type } = selectedMarche; // Récupération du type de marché ici
        let { value, date, hour, difference } = marketData[id] || {};
        const userId = user.id;

        // Vérifier que l'heure est correctement formatée
        if (!hour || typeof hour !== 'string' || hour.split(':').length !== 2) {
            console.error('Heure invalide:', hour);
            // alert('Erreur: L\'heure est invalide.');
            return;
        }

        // Extraire les heures et minutes depuis la chaîne de caractères hour
        const [hourPart, minutePart] = hour.split(':').map(Number);

        if (isNaN(hourPart) || isNaN(minutePart)) {
            console.error('Heure ou minute invalide:', { hourPart, minutePart });
            // alert('Erreur: L\'heure ou la minute est invalide.');
            return;
        }

        // Arrondir la valeur à deux décimales
        value = parseFloat(value).toFixed(2);

        const formattedTime = `${String(hourPart).padStart(2, '0')}:${String(minutePart).padStart(2, '0')}`;

        // Construire l'objet Date à partir des informations de date, heure et minute
        const dateObj = new Date(date);

        if (isNaN(dateObj.getTime())) {
            console.error('Date invalide:', date);
            alert('Erreur: La date est invalide.');
            return;
        }

        dateObj.setHours(hourPart + 1); // Ajuster l'heure pour le fuseau horaire GMT+1
        dateObj.setMinutes(minutePart);
        dateObj.setSeconds(0);
        // console.log('dateObj', dateObj);

        // Formater la date dans le format 'YYYY-MM-DD HH:MM:SS'
        const formattedDate = dateObj.toISOString().slice(0, 19).replace('T', ' ');
        // console.log('Date formattée:', formattedDate);

        try {
            const response = await postAchat(userId, id, value, type); // Inclure le type de marché dans la requête
            // console.log('Achat effectué avec succès:', response);
            navigate('/portefeuille_vente', { state: { id, titre, icon, value, formattedTime, difference, type } });
        } catch (error) {
            console.error('Erreur lors de l\'achat:', error);
            alert('Une erreur est survenue lors de l\'achat. Veuillez réessayer.');
        }
    };



    if (!selectedMarche) return null;

    const { id, icon, titre } = selectedMarche;
    const { hour, minute, value, date, difference } = marketData[id] || {};

    const queryParams = new URLSearchParams({
        id,
        logo: encodeURIComponent(icon),
        titre: encodeURIComponent(titre),
        hour: hour || 'N/A',
        minute: minute || 'N/A',
        value: value || 'N/A',
        date: date || 'N/A'
    }).toString();
    // console.log(queryParams)

    const latestValue = marketData && marketData.values && marketData.values.length > 0
        ? marketData.values[0].value
        : 'N/A';

    const marketPageMap = {
        "Actions": "/portefeuille_actions/Actions",
        "Matiére premiéres": "/portefeuille_actions/Matiére premiéres",
        "Crypto-monnaies": "/portefeuille_actions/Crypto-monnaies",
        "Divies": "/portefeuille_actions/Divies"
    };

    const test = () => {
        console.log()
    }




    return (
        <div className="bg-black w-full relative overflow-auto h-[100%] py-7 pb-12">
            <div className="grid  lg:grid-cols-1   md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col  gap-4 sm:px-10 md:px-16">
                <div className="lg:w-[98%] w-[98%] p-2 lg:border border-gray-400 lg:rounded-lg  sm:border md:border  xl:border 2xl:border shadow bg-balck text-white flex justify-center mx-auto  py-1 lg:py-4  flex-col border-b ">

                    {/* <div className="flex gap-4">
                        <div className="relative">
                            <img src={totalenergielogo} className=" w-auto h-auto" alt="Logo principal" />
                            <img src={francelogo} className="w-auto h-auto absolute top-0 -left-2 transform translate-x-1/2 -translate-y-1/2" alt="Petite image" />
                        </div>
                        <div>
                            <div className="flex  gap-4">
                                <p>60.10 €</p>
                                <p>
                                    <span className="">
                                        <svg class="w-5 h-5 text-yellow-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 24 24">
                                            <path d="M13.849 4.22c-.684-1.626-3.014-1.626-3.698 0L8.397 8.387l-4.552.361c-1.775.14-2.495 2.331-1.142 3.477l3.468 2.937-1.06 4.392c-.413 1.713 1.472 3.067 2.992 2.149L12 19.35l3.897 2.354c1.52.918 3.405-.436 2.992-2.15l-1.06-4.39 3.468-2.938c1.353-1.146.633-3.336-1.142-3.477l-4.552-.36-1.754-4.17Z" />
                                        </svg>

                                    </span></p>
                            </div>
                            <div className="flex  gap-3  text-sm">
                                <p className="text-red-400 text-sm">-0.33 (-0,55%)</p>
                                <p>
                                    <span className="">
                                        <svg class="w-6 h-6 text-red-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 9-7 7-7-7" />
                                        </svg>


                                    </span></p>
                            </div>
                        </div>
                    </div> */}
                    <div className={`flex gap-2 px-3 ${cardsPerPage === 2 ? 'sm:flex-row' : 'md:flex-row'}`}>
                        {marches.map(marche => (
                            <div key={marche.id} onClick={() => handleClick(marche)} className={` cursor-pointer  bg-slate-900 rounded-2xl  px-1   items-center p-2 flex gap-2 flex-row  shadow-md ${cardsPerPage === 2 ? 'w-full sm:w-[100%]' : 'w-full md:w-1/3'}`}>
                                <img
                                    src={`https://tradingseries.fr/admin/storage/app/public/${marche.icon}`} // URL des images
                                    alt={marche.titre} className="w-11 h-11  rounded-full mb-2" />
                                <div className="flex flex-col  ">
                                    <p className="text-[10px] font-bold text-amber-300 ">{marche.type}</p>
                                    <p className="text-[10px] font-semibold">{marche.titre}</p>


                                    <p className="text-[9px] font-semibold">
                                        {marketData[marche.id]
                                            ? `${marketData[marche.id].value}€`
                                            : ''}
                                    </p>
                                    <p className="text-[8px] font-semibold">
                                        {marketData[marche.id]
                                            ? `  ${marketData[marche.id].date}`
                                            : ''}
                                    </p>
                                    <p className="text-[8px] font-semibold">
                                        {marketData[marche.id]
                                            ? `  ${marketData[marche.id].hour}`
                                            : ''}
                                    </p>

                                </div>
                            </div>
                        ))}
                    </div>
                    <StockChart marcheId={marchesid} userId={userId}  />
                  
                    <div className='flex justify-center items-center'>
                        {selectedMarche && (

                            <div className=" flex  items-center  justify-between gap-1  py-4">

                                <div className="  flex  justify-center  items-center gap-4" >

                                    <button className="py-3 px-3 text-[14px] bg-[#678f2c] rounded-sm text-black font-medium" onClick={handleAchat}
                                    >
                                        ACHAT
                                    </button>
                                    <button className="py-3 px-3 text-[14px] bg-[#97271d] rounded-sm text-black font-medium" onClick={handleVente}
                                    >
                                        VENTE
                                    </button>




                                </div>
                            </div>
                        )}

                    </div>
                    <div className="flex flex-col gap-3 py-2">
                        <div className=" flex justify-between mx-4 text-sm">
                            <p>Volume (3M) :</p>
                            <p>3,98M</p>
                        </div>
                        <div className=" flex justify-between mx-4 text-sm">
                            <p>Capitalisation boursière :</p>
                            <p>  143,99B</p>
                        </div>
                    </div>


                </div>
                <div className="lg:w-[98%] w-[98%] p-2  sm:border md:border lg:border xl:border 2xl:border border-gray-200 lg:rounded-lg shadow bg-balck text-white flex py-2 lg:pt-20 mx-auto   flex-col  ">
                    <div className="flex  flex-col gap-3 mx-3">
                        <p className="text-lg font-medium  text-justify">Profil</p>
                        <p className="text-[13px] lg:text-lg font-medium  text-justify">TotalEnergies est une entreprise énergétique internationale de premier plan, opérant dans toutes les phases de la chaîne de valeur de l'énergie. Anciennement connue sous le nom de Total, elle s'est repositionnée en tant que TotalEnergies pour refléter son engagement envers la transition énergétique. La société est active dans l'exploration, la production, le raffinage, la distribution et la commercialisation de produits pétroliers, de gaz naturel et d'énergies renouvelables. Forte de son expérience mondiale, TotalEnergies s'efforce de développer des solutions énergétiques durables et innovantes pour répondre aux besoins croissants de la planète en matière d'énergie.</p>
                    </div>

                </div>
            </div>


        </div>

    )
};
export default PorteFeuilleDetail;
