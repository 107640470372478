
import React from 'react';

const CustomButton = ({ icon, onClick,style,type, text, styleinput, ...rest }) => {
    return (
        <>
            <button className={` hover:bg-gray-400  border rounded-md text-black font-bold w-[100%]  py-1.5 flex gap-4 pl-4 pr-4 ${styleinput}`} type={type} onClick={onClick}>
                {icon}
                {text}
            </button>
        </>
    );
}

export default CustomButton;
