// src/StackedBarChart.js
// import React from 'react';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// const StackedBarChart = () => {
//     const data = {
//         labels: ['Semaine 1', 'Semaine 2', 'Semaine 3', 'Semaine 4'],
//         datasets: [
//             {
//                 label: 'Tom T.',
//                 backgroundColor: '#545454',
//                 data: [20000, 18000, 17000, 15000],
//             },
//             {
//                 label: 'Leo N.',
//                 backgroundColor: '#737373',
//                 data: [20000, 18000, 17000, 15000],
//             },
//             {
//                 label: 'Louis R..',
//                 backgroundColor: '#a6a6a6',
//                 data: [20000, 18000, 17000, 15000],
//             },
//             {
//                 label: 'Adriano I.',
//                 backgroundColor: '#FFF',
//                 data: [20000, 18000, 17000, 15000],
//             },
//         ],
//     };

//     const options = {
//         layout: {
//             padding: {
//                 top: 1, // Ajoute de l'espace entre le haut du conteneur et le graphique
//                 bottom: 20 // Ajoute de l'espace entre le bas du conteneur et le graphique
//             }
//         },
//         plugins: {
//             title: {
//                 display: true,
//             },

//             legend: {
//                 display: true,
//                 position: 'top',
//                 labels: {
//                     pointStyle: 'circle', // Utiliser le style de point "circle"
//                     usePointStyle: true, // Utiliser des styles de points pour les légendes
//                     pointRadius: 2, // Modifier le rayon des cercles de légende
//                     pointHoverRadius: 2, // Modifier le rayon des cercles de légende au survol
//                     pointBorderWidth: 2, // Modifier la largeur de bordure des cercles de légende
//                     boxWidth: 3, // Modifier la largeur des carrés de légende
//                     boxHeight: 4,
//                     padding: 10, // Ajouter du padding autour des labels de légende


//                 },
//             },
//         },
//         responsive: true,
//         maintainAspectRatio: false,
//         scales: {
//             x: {
//                 stacked: true,
//                 max: 80000,

//             },
//             y: {
//                 stacked: true,

//                 suggestedMax: 80000,
//             },
//         },
//     };

//     return (
//         <div style={{ width: '100%', height: '60vh' }}>
//             <Bar data={data} options={options} />
//         </div>
//     );
// };



import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { getclassementbyteam } from '../../../Api/authApi.js';
import { useSelector } from 'react-redux';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StackedBarChart = ({ userId }) => {
    const [chartData, setChartData] = useState(null);
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getclassementbyteam(user.id);
                // console.log(result);
                
                const labels = Object.keys(result.weekly_totals);
                const datasets = [];

                result.team_members.forEach((member, memberIndex) => {
                    const memberData = {
                        label: member.name,
                        backgroundColor: getRandomColor(memberIndex), 
                        data: []
                    };

                    labels.forEach((week) => {
                        const weekData = result.weekly_totals[week].members.find(m => m.member_id === member.id);
                        memberData.data.push(weekData ? weekData.total : 0);
                    });

                    datasets.push(memberData);
                });

                setChartData({
                    labels: labels,
                    datasets: datasets,
                });
            } catch (error) {
                console.error('Erreur lors de la récupération des données du classement:', error);
            }
        };

        fetchData();
    }, [userId, user.id]);

    const getRandomColor = (index) => {
        const colors = [
            '#545454', '#737373', '#a6a6a6', '#FFF', 
            '#FF5733', '#33FF57', '#3357FF', '#FF33A6'
        ]; 
        return colors[index % colors.length];
    };

    const options = {
        layout: {
            padding: {
                top: 1,
                bottom: 6,
            },
        },
        plugins: {
            title: {
                display: true,
            },
            legend: {
                display: true,
                position: 'top',
                labels: {
                    pointStyle: 'circle',
                    usePointStyle: true,
                    padding: 8,
                },
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                suggestedMax: 1000,
            },
        },
    };

    return (
        <div style={{ width: '100%', height: '60vh' }}>
            {chartData ? (
                <Bar data={chartData} options={options} />
            ) : (
                <p>Chargement des données...</p>
            )}
        </div>
    );
};

export default StackedBarChart;
