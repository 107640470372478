import React from 'react'
import logotrading from '../../images/logotrading.png'

const CustomHeader = ({ width }) => {
  return (
    <>
      
      <img class={`w-auto h-auto lg:${width}`} src={logotrading} alt="logotrading" />
    </>
  )
}

export default CustomHeader