/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";

import photoequipe from '../../images/photoequipe.png'
import class1 from '../../images/classementimg/class1.png'
import class2 from '../../images/classementimg/class2.png'
import class3 from '../../images/classementimg/class3.png'

import Drawer from "../../components/Drawer";
import { Link } from "react-router-dom";
import Navbarfooter from "../../components/Navbarfooter";
import StackedBarChart from "../../components/chartgraphique/BarChart";
import { getUserWithTeam, getclassementbyuser, getclassementbyteam } from '../../Api/authApi.js';
import { useDispatch, useSelector } from 'react-redux';

const Classement = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamname, setteamname] = useState('');

    useEffect(() => {
        const fetchclassementbyteam = async () => {
            try {
                const data = await getclassementbyteam(user.id);
                setTeamMembers(data.team_members); // Met à jour l'état avec les membres de l'équipe
                setteamname(data.team_name);
                // console.log('Team Members:', data);


            } catch (error) {
                console.error('Erreur lors de la récupération des informations de l\'utilisateur :', error.message);
            }
        };

        if (user.id) {
            fetchclassementbyteam();
        }
    }, [user.id]);
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const dataequipe = [
        {
            id: 1,
            membername: "Adriano I.",
            imgmember: photoequipe
        },
        {
            id: 2,
            membername: "Louis R..",
            imgmember: photoequipe


        },
        {
            id: 3,
            membername: "Leo N.",
            imgmember: photoequipe


        },
        {
            id: 4,
            membername: "Tom T.",
            imgmember: photoequipe


        },

    ];
    const teams = [
        { name: "Equipe 1", value: "85 000$", icon: class1, iconColor: "text-green-600" },
        { name: "Equipe 2", value: "75 000$", icon: class2, iconColor: "text-red-600" },
        { name: "Equipe 3", value: "90 000$", icon: class3, iconColor: "text-green-600" },
        { name: "Equipe 4", value: "60 000$", icon: null, iconColor: "text-green-600" },
        { name: "Equipe 5", value: "95 000$", icon: null, iconColor: "text-red-600" },
        { name: "Equipe 6", value: "80 000$", icon: null, iconColor: "text-green-600" },
        { name: "Equipe 7", value: "70 000$", icon: null, iconColor: "text-red-600" },
        { name: "Equipe 8", value: "85 500$", icon: null, iconColor: "text-green-600" },
        { name: "Equipe 9", value: "88 000$", icon: null, iconColor: "text-red-600" }
    ];
    const getInitials = (name) => {
        return name.split(' ').map(word => word[0]).join('').toUpperCase();
    }
    const [currentIndex, setCurrentIndex] = useState(0);
    const itemsPerPage = 4;

    const nextSlide = () => {
        if (currentIndex < teamMembers.length - itemsPerPage) {
            setCurrentIndex(currentIndex + itemsPerPage);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - itemsPerPage);
        }
    };


    return (
        <div className="bg-black w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">
            <div className="grid  lg:grid-cols-2  md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 grid-cols-1 sm:grid-cols-1 flex-col items-center justify-center  gap-7 sm:px-10 md:px-16">

                <div className="lg:w-[98%] w-[98%] lg:py-10  px-2  lg:border lg:border-gray-200 lg:rounded-lg shadow bg-black text-white flex flex-col gap-1 mx-auto border-b border-gray-500 ">
                    <p className=" font-medium shadow-md py-2">{teamname}</p>
                  
                    <div className="flex flex-col items-center">
                    <div className="flex absolute justify-between mt-4 w-full">
                            <button
                                onClick={prevSlide}
                                disabled={currentIndex === 0} className="bg-gray-800 text-white p-1.5 rounded-full shadow-md hover:bg-gray-700 transition duration-300"
                            >
                                <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 1 1.3 6.326a.91.91 0 0 0 0 1.348L7 13" />
                                </svg>
                            </button>
                            <button
                              onClick={nextSlide}
                              disabled={currentIndex >= teamMembers.length - itemsPerPage}
                                className="bg-gray-800 text-white p-1.5 rounded-full shadow-md hover:bg-gray-700 transition duration-300"
                            >

                                <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1" />
                                </svg>
                            </button>

                          
                        </div>
                        <div className="flex gap-1 items-center justify-center">
                            {teamMembers.slice(currentIndex, currentIndex + itemsPerPage).map(member => (
                                <div key={member.id} className="relative">
                                    <img className="rounded-full w-16 h-16" src={photoequipe} alt={member.name} />
                                    <div className="absolute top-3 bottom-7 left-3 right-3 flex justify-center text-white font-Playfair items-center font-bold text-[27px]">
                                        {getInitials(member.name)}
                                    </div>
                                    <p className="text-center font-medium text-[11px]">{member.name}</p>
                                </div>
                            ))}
                        </div>
                   
                    </div>

                  

                    <StackedBarChart />
                </div>
                <div className="lg:w-[98%] w-[100%] lg:pt-4  lg:border md:border  sm:border rounded-lg shadow bg-balck text-white flex  mx-auto flex-col px-3 ">
                    <p className=" font-medium">Classement général</p>

                    {teams.map((team, index) => (
                        <div key={index} className="flex justify-between px-3 py-4 border-b border-gray-400">
                            <div className="flex gap-3 items-center">
                                {team.icon ? (
                                    <img src={team.icon} className="w-6 h-6" alt={team.name} />
                                ) : (
                                    <div className="w-7 h-7"></div>
                                )}
                                <p className="text-[14px]  font-medium">"{team.name}"</p>
                            </div>
                            <div className="flex gap-2 items-center font-bold">
                                <p className="text-[14px]">{team.value}</p>
                                {team.iconColor.includes("green") ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="green" class="bi bi-chevron-up" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="red" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                    </svg>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

            </div>


        </div>

    )
};
export default Classement;
