
// import React, { useState, useEffect } from "react";
// import { useParams } from 'react-router-dom';
// import start from '../../images/start.png';
// import dislike from '../../images/icons/dislike.png';
// import likeicon from '../../images/icons/likeicon.png';
// import { getArticleById,addLike,addrate,adddislike } from '../../Api/authApi.js';
// import { FaRegThumbsUp, FaRegThumbsDown, FaRegStar, FaThumbsUp, FaThumbsDown, FaStar } from 'react-icons/fa'; // Importation des icônes

// const ArticleDetail = () => {
//     const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//     const [article, setArticle] = useState([]);
//     const [lik, setlik] = useState([]);
//     const [error, setError] = useState(null);
//     const { id } = useParams();

//     const [likeClicked, setLikeClicked] = useState(false);
//     const [dislikeClicked, setDislikeClicked] = useState(false);
//     const [starClicked, setStarClicked] = useState(false);

//     const handleLikeClick = async() => {
//         try {
//             const response = await addLike(id);
//             // setlik(response);
//             // setLikeClicked(!likeClicked);
//             console.log(response)
//         } catch (err) {
//             setError(err.message);
//         }
//         // if (!likeClicked && dislikeClicked) {
//         //     setDislikeClicked(false); // Désélectionner dislike si like est sélectionné
//         // }
//     };

//     const handleDislikeClick = () => {
//         setDislikeClicked(!dislikeClicked);
//         if (!dislikeClicked && likeClicked) {
//             setLikeClicked(false); // Désélectionner like si dislike est sélectionné
//         }
//     };

//     const handleStarClick = () => {
//         setStarClicked(!starClicked);
//     };
//     useEffect(() => {
//         const fetchArticle = async () => {
//             try {
//                 const response = await getArticleById(id);
//                 setArticle(response);
//                 // console.log(response)
//             } catch (err) {
//                 setError(err.message);
//             }
//         };

//         fetchArticle();
//     }, [id]);



//     return (
//         <div className="bg-black text-white w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">
//             <div className="flex flex-col items-center justify-center sm:px-10 md:px-16 px-4">
//                 <h1 className="text-md lg:text-xl py-3 text-center font-semibold" style={{ fontStyle: 'italic' }}>{article.title}</h1>
//                 <img className="w-[100%] h-auto object py-2" src={`https://tradingseries.fr/admin/public${article.image}`} alt={article.title} />
//                 <div className="mt-1 font-Averia justify-evenly text-sm lg:text-lg text-justify" dangerouslySetInnerHTML={{ __html: article.content }}></div>
//                 <div className="flex gap-2 mt-4 border-t border-gray-400 py-3">
//                     <p className="text-[14px] items-center">Cet article a-t-il été utile ?</p>



//                     <button onClick={handleLikeClick}>
//                         {likeClicked ? <FaThumbsUp className="text-white text-3xl" /> : <FaRegThumbsUp className="text-white text-3xl" />}
//                     </button>

//                     {/* Dislike Button */}
//                     <div onClick={handleDislikeClick}>
//                         {dislikeClicked ? <FaThumbsDown className="text-white text-3xl" /> : <FaRegThumbsDown className="text-white text-3xl" />}
//                     </div>

//                     {/* Star Button */}
//                     <div onClick={handleStarClick}>
//                         {starClicked ? <FaStar className="text-white text-3xl" /> : <FaRegStar className="text-white text-3xl" />}
//                     </div>
//                 </div>
//             </div>
//             <div className="py-2 my-2 flex justify-center bg-white text-black w-[94%] mx-4  items-center">
//                 <input className="bg-white w-[70%] font-semibold p-2 text-black text-md lg:text-lg" placeholder="Écrire un commentaire"></input>
//                 <button className="bg-white w-auto  font-semibold p-2 text-black text-md lg:text-lg">Valider</button>
//             </div>
//         </div>
//     );
// };

// export default ArticleDetail;

import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getArticleById, addLike, addRate, addDislike, addComment } from '../../Api/authApi.js';
import { FaRegThumbsUp, FaRegThumbsDown, FaRegStar, FaThumbsUp, FaThumbsDown, FaStar } from 'react-icons/fa'; // Importation des icônes
import { useDispatch, useSelector } from 'react-redux';

const ArticleDetail = () => {
    const [article, setArticle] = useState({});
    const [error, setError] = useState(null);
    const { id } = useParams();
    const [comments, setComments] = useState([]); // Nouvel état pour stocker les commentaires


    const [likeClicked, setLikeClicked] = useState(false);
    const [dislikeClicked, setDislikeClicked] = useState(false);
    const [starClicked, setStarClicked] = useState(false);
    const [newComment, setNewComment] = useState(''); // Nouvel état pour le commentaire
    const userId = useSelector((state) => state.auth.user.id);

    const handleLikeClick = async () => {
        try {
            const response = await addLike(id, userId);
            setLikeClicked(true);
            setDislikeClicked(false); // Désélectionner dislike
            console.log(response.data)
            // setArticle({ ...article, likes: response.likes }); // Met à jour le nombre de likes
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDislikeClick = async () => {
        try {
            const response = await addDislike(id, userId);
            setDislikeClicked(true);
            setLikeClicked(false); // Désélectionner like
            // console.log(response.data)

        } catch (err) {
            setError(err.message);
        }
    };

    const handleStarClick = async () => {
        try {
            const response = await addRate(id, userId);
            setStarClicked(true);
            // console.log(response.data)
        } catch (err) {
            setError(err.message);
        }
    };
    const handleCommentSubmit = async () => {
        try {
            const response = await addComment(id, userId, newComment);
            // Mettez à jour l'article avec le nouveau commentaire
            // Ajouter le nouveau commentaire à la liste des commentaires
            setComments([...comments, response.data.newComment]);
            // Réinitialisez le champ de commentaire
            setNewComment('');

            setArticle(response.data);
            console.log(response.data)
        } catch (err) {
            setError(err.message);
        }
    };


    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await getArticleById(id);
                setArticle(response);
                console.log("articledata",response)
            } catch (err) {
                setError(err.message);
            }
        };

        fetchArticle();
    }, [id]);

    return (
        <div className="bg-black text-white w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">
            <div className="flex flex-col items-center justify-center sm:px-10 md:px-16 px-4">
                <h1 className="text-md lg:text-xl py-3 text-center font-semibold" style={{ fontStyle: 'italic' }}>{article.title}</h1>
                <img className="w-[100%] h-auto object py-2" src={`https://tradingseries.fr/admin/storage/app/public/${article.image}`}alt={article.title} />
                <div className="mt-1 font-Averia justify-evenly text-sm lg:text-lg text-justify" dangerouslySetInnerHTML={{ __html: article.content }}></div>
                <div className="flex gap-2 mt-4 border-t border-gray-400 py-3">
                    <p className="text-[14px] items-center">Cet article a-t-il été utile ?</p>

                    <button onClick={handleLikeClick}>
                        {likeClicked ? <FaThumbsUp className="text-white text-xl" /> : <FaRegThumbsUp className="text-white text-xl" />}
                    </button>

                    <button onClick={handleDislikeClick}>
                        {dislikeClicked ? <FaThumbsDown className="text-white text-xl" /> : <FaRegThumbsDown className="text-white text-xl" />}
                    </button>

                    <button onClick={handleStarClick}>
                        {starClicked ? <FaStar className="text-white text-xl" /> : <FaRegStar className="text-white text-xl" />}
                    </button>
                </div>
            </div>
            <div className="py-2 my-2 flex justify-center bg-white text-black w-[94%] mx-4  items-center">
                <input
                    className="bg-white w-[70%] font-semibold p-2 text-black text-md lg:text-lg"
                    placeholder="Écrire un commentaire"
                // value={newComment}
                // onChange={(e) => setNewComment(e.target.value)}
                />
                <button
                    className="bg-white hidden w-auto font-semibold p-2 text-black text-md lg:text-lg"
                    onClick={handleCommentSubmit}
                >
                    Valider
                </button>
            </div>
        </div>
    );
};

export default ArticleDetail;
