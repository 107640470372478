import React, { useState } from "react";
import CustomInput from '../../components/custominput/index.js';
import CustomButton from '../../components/custombutton/index.js';
import { resetPassword } from '../../Api/authApi.js'; // Make sure your API call is properly configured
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import key from '../../images/key.png';
import unlock from '../../images/unlock.png';
import next from '../../images/next.png';
import CustomHeader from '../../components/customheader/index.js';
import CustomFooter from '../../components/customfooter/index.js';
import { Link } from 'react-router-dom';

// const PasswordReset = () => {
//   const navigate = useNavigate();
//   const { token } = useParams();
//   const location = useLocation();
//   const email = new URLSearchParams(location.search).get('email');

//   const [resetData, setResetData] = useState({
//     email: email || '',
//     password: '',
//     password_confirmation: '',
//     token: token || '', // Ensure token is included
//   });

//   const handleChange = (e) => {
//     setResetData({ ...resetData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await resetPassword(resetData); // Send resetData containing token
//       toast.success(response.message);

//       setResetData({ email: '', password: '', password_confirmation: '', token: '' }); // Clear form fields
//       navigate('/connexion');
//     } catch (error) {
//       toast.warning(error.response.data.message);
//       console.log('Erreur lors de la requête :', error); // Affichez l'erreur pour le débogage

//     }
//   };
  

const PasswordReset = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const email = new URLSearchParams(location.search).get('email');

  const [resetData, setResetData] = useState({
    email: email || '',
    password: '',
    password_confirmation: '',
    token: token || '', // Assurez-vous que le token est inclus
  });

  const handleChange = (e) => {
    setResetData({ ...resetData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await resetPassword(resetData); // Envoyer resetData contenant le token
      toast.success(response.message);
      console.log(email,token)

      setResetData({ email: '', password: '', password_confirmation: '', token: '' }); // Réinitialiser les champs du formulaire
      navigate('/connexion');
    } catch (error) {
      toast.warning(error.response.data.message);
    }
  };

  return (
    <section className=' bg-black text-white relative'>
      <Link to='/' className='absolute left '>
        <img class={`w-16 h-16 pl-8 pt-9`} src={next} alt="logotrading" />
      </Link>
      <div className='  py-9 text-white w-[100vw]  h-[100vh] flex justify-center'>

        <div className='flex items-center flex-col space-y-3 '>
          <div className='flex  justify-center pt-6'>
            <CustomHeader width="lg:w-[90%] w-[50%]" />

          </div>
          <div className='items-center flex-col space-y-4  w-[60%] sm:w-[50%]  md:w-[50%]   lg:w-[50%]  xl:w-[50%]  2xl:w-[50%] '>
            <form className="gap-3 flex flex-col" onSubmit={handleSubmit} method="POST">


              <CustomInput
                styleinput="pl-14 py-1.5 lg:py-3 rounded-sm font-medium"
                icon={<img src={key} className="w-6 h-6" alt="key" />}
                placeholder='e-mail'
                type="email"
                name="email"
                value={resetData.email}
                onChange={handleChange}
              />

              <CustomInput
                styleinput="pl-14 py-1.5   lg:py-3  rounded-sm font-medium "

                icon={<img src={key} class=" w-6 h-6 placeholder-red-300" />}
                placeholder='nouveau  mot de passe'
                type="password"
                name="password"
                value={resetData.password}
                onChange={handleChange}
              />
              <CustomInput
                styleinput="pl-14 py-1.5   lg:py-3  rounded-sm font-medium "

                icon={<img src={key} class=" w-6 h-6 placeholder-red-300" />}
                placeholder='confirme mot de passe'
                type="password"
                name="password_confirmation"
                value={resetData.password_confirmation}
                onChange={handleChange}
              />
              <CustomButton
                icon={<img src={unlock} class=" w-6 h-5" />}
                text='S&rsquo;identifier'
                styleinput="bg-gray-300  mt-2 lg:py-3 font-semibold text-sm gap-6 py-2.5 rounded-sm"

              />
            </form>
          </div>
          <div className='items-center flex-col space-y-4 pt-1 w-[60%] sm:w-[50%]  md:w-[50%]   lg:w-[50%]  xl:w-[50%]  2xl:w-[50%] '>
            {/* <Link to='/accueil'> */}
            {/* </Link> */}

          </div>

          <div className='mt-7'>
            <CustomFooter styelefooter='text-[15px]  mx-1  lg:py-3 ' />
          </div>

        </div>

      </div>
    </section>

  )
}

export default PasswordReset