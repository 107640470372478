
// import React, { useState, useEffect, useRef } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
// import axios from 'axios';

// const API_URL = 'https://tradingseries.fr/admin/public/api';

// ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// // timeOptions defined here
// const timeOptions = [
//   { key: '1min', function: 'getDataForCurrentDay' },
//   { key: '5min', function: 'getDataForLast5Minutes' },
//   { key: '15min', function: 'getDataForLast15Minutes' },
//   { key: '30min', function: 'getDataForLast30Minutes' },
//   { key: '1h', function: 'getDataForLastHour' },
//   { key: '4h', function: 'getDataForLast4Hours' },
//   { key: '1d', function: 'getDataForLastDay' },
//   { key: '1w', function: 'getDataForLastWeek' }
// ];

// const ProgressiveLineChart = ({ selectedTime, marcheId }) => {
//   const [data, setData] = useState([]);
//   const [labels, setLabels] = useState([]);
//   const [fullLabels, setFullLabels] = useState([]);
//   const chartRef = useRef();

//   useEffect(() => {
//     const fetchData = async (timeKey) => {
//       const timeOption = timeOptions.find(option => option.key === timeKey);
//       if (timeOption) {
//         try {
//           const response = await axios.get(`${API_URL}/${timeOption.function}/${marcheId}`);
//           const data = response.data;
//           const date = data.date;
//           const values = data.values.map(item => item.value);
//           const fullLabels = data.values.map(item => `${date} ${item.heures}`);

//           setData(values);
//           // console.log(values)
//           setLabels(data.values.map(item => item.heures));
//           setFullLabels(fullLabels);
//         } catch (error) {
//           console.error('Erreur lors de la récupération des données:', error);
//         }
//       }
//     };

//     fetchData(selectedTime);
//   }, [selectedTime, marcheId]); // Effectue une nouvelle récupération des données lorsque selectedTime ou marcheId change

//   const handlePointClick = (event, elements) => {
//     if (elements.length > 0) {
//       const elementIndex = elements[0].index;
//       const value = data[elementIndex];
//       const fullLabel = fullLabels[elementIndex];
//       console.log(`Point cliqué - Date et Heure: ${fullLabel}, Valeur: ${value}`);
//     }
//   };

//   const chartData = {
//     labels: labels,
//     datasets: [
//       {
//         borderColor: 'gray',
//         borderWidth: 1,
//         radius: 1,
//         data: data,
//       }
//     ]
//   };

//   const options = {
//     animation: false,
//     interaction: {
//       intersect: false,
//       mode: 'nearest'
//     },
//     plugins: {
//       legend: {
//         display: false
//       },
//       tooltip: {
//         callbacks: {
//           label: (context) => {
//             const index = context.dataIndex;
//             const fullLabel = fullLabels[index];
//             const value = data[index];
//             return `${fullLabel}\n ${value}`;
//           }
//         }
//       }
//     },
//     onClick: handlePointClick,
//     scales: {
//       x: {
//         type: 'category',
//         title: {
//           display: true,
//           text: 'Heures'
//         }
//       },
//       y: {
//         type: 'linear',
//         title: {
//           display: false,
//           text: ''
//         }
//       }
//     }
//   };

//   return (
//     <div style={{ width: '100%', height: '100%' }}>
//       <div style={{ position: 'relative', width: '100%', height: '100%' }}>
//         <Line ref={chartRef} data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default ProgressiveLineChart;
import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
const API_URL = 'http://127.0.0.1:8000/api';

// const API_URL = 'https://tradingseries.fr/admin/public/api';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// timeOptions defined here
const timeOptions = [
  { key: '1min', function: 'getDataForCurrentDay' },
  { key: '5min', function: 'getDataForLast5Minutes' },
  { key: '15min', function: 'getDataForLast15Minutes' },
  { key: '30min', function: 'getDataForLast30Minutes' },
  { key: '1h', function: 'getDataForLastHour' },
  { key: '4h', function: 'getDataForLast4Hours' },
  { key: '1d', function: 'getDataForLastDay' },
  { key: '1w', function: 'getDataForLastWeek' }
];

const ProgressiveLineChart = ({ selectedTime, marcheId ,userId}) => {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [fullLabels, setFullLabels] = useState([]);
  const chartRef = useRef();

  const fetchData = async (timeKey) => {
    const timeOption = timeOptions.find(option => option.key === timeKey);
    if (timeOption) {
      try {
        const response = await axios.get(`${API_URL}/${timeOption.function}/${marcheId}/${userId}`);
        const data = response.data;
        console.log('ProgressiveLineChart',data)

        if (data && data.values) {
          const date = data.date;
          const values = data.values.map(item => item.value);
          const fullLabels = data.values.map(item => `${date} ${item.heures}`);

          setData(values);
          setLabels(data.values.map(item => item.heures));
          setFullLabels(fullLabels);
        } else {
          // Si aucune donnée n'est récupérée, réinitialiser les données
          setData([]);
          setLabels([]);
          setFullLabels([]);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    }
  };

  useEffect(() => {
    // Fetch initial data
    fetchData(selectedTime);

    // Set up interval to fetch data every minute
    const intervalId = setInterval(() => {
      fetchData(selectedTime);
    }, 60000); // 60000ms = 1 minute

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [selectedTime, marcheId]);

  const handlePointClick = (event, elements) => {
    if (elements.length > 0) {
      const elementIndex = elements[0].index;
      const value = data[elementIndex];
      const fullLabel = fullLabels[elementIndex];
      // console.log(`Point cliqué - Date et Heure: ${fullLabel}, Valeur: ${value}`);
    }
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        borderColor: 'gray',
        borderWidth: 1,
        radius: 1,
        data: data,
      }
    ]
  };

  const options = {
    animation: false,
    interaction: {
      intersect: false,
      mode: 'nearest'
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const index = context.dataIndex;
            const fullLabel = fullLabels[index];
            const value = data[index];
            return `${fullLabel}\n ${value}`;
          }
        }
      }
    },
    onClick: handlePointClick,
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: ''
        }
      },
      y: {
        type: 'linear',
        title: {
          display: true,
          text: ''
        }
      }
    }
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Line ref={chartRef} data={chartData} options={options} />
      </div>
    </div>
  );
};

export default ProgressiveLineChart;
// import React, { useState, useEffect, useRef } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
// import axios from 'axios';

// const API_URL = 'http://127.0.0.1:8000/api';
// // const API_URL = 'https://tradingseries.fr/admin/public/api';

// ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// // Options de temps pour les différents intervalles
// const timeOptions = [
//   { key: '1min', function: 'getDataForCurrentDay' },
//   { key: '5min', function: 'getDataForLast5Minutes' },
//   { key: '15min', function: 'getDataForLast15Minutes' },
//   { key: '30min', function: 'getDataForLast30Minutes' },
//   { key: '1h', function: 'getDataForLastHour' },
//   { key: '4h', function: 'getDataForLast4Hours' },
//   { key: '1d', function: 'getDataForLastDay' },
//   { key: '1w', function: 'getDataForLastWeek' }
// ];

// const ProgressiveLineChart = ({ selectedTime, marcheId }) => {
//   const [data, setData] = useState([]);
//   const [labels, setLabels] = useState([]);
//   const [fullLabels, setFullLabels] = useState([]);
//   const chartRef = useRef();

//   // Fonction pour récupérer les données depuis l'API
//   const fetchData = async (timeKey) => {
//     const timeOption = timeOptions.find(option => option.key === timeKey);
//     if (timeOption) {
//       try {
//         // console.log('Fetching data for:', timeKey); // Log pour le débogage
//         const response = await axios.get(`${API_URL}/${timeOption.function}/${marcheId}`);
//         const data = response.data;
//         // console.log('Data received:', data); // Log pour voir les données reçues

//         if (data && data.values) {
//           const date = data.date;
//           const values = data.values.map(item => item.value);
//           const fullLabels = data.values.map(item => `${date} ${item.heures}`);

//           // Forcer la mise à jour de l'état en utilisant une nouvelle référence pour les données
//           setData([...values]);
//           setLabels([...data.values.map(item => item.heures)]);
//           setFullLabels([...fullLabels]);
//         } else {
//           // Réinitialiser les données si aucune donnée n'est récupérée
//           setData([]);
//           setLabels([]);
//           setFullLabels([]);
//         }
//       } catch (error) {
//         console.error('Erreur lors de la récupération des données:', error);
//       }
//     }
//   };

//   // useEffect pour gérer la récupération des données et la mise en place de l'intervalle
//   useEffect(() => {
//     // console.log('useEffect triggered'); // Log pour voir quand useEffect est déclenché
//     fetchData(selectedTime); // Appel initial pour récupérer les données

//     // Configuration de l'intervalle pour récupérer les données toutes les minutes
//     const intervalId = setInterval(() => {
//       fetchData(selectedTime);
//     }, 60000); // 60000ms = 1 minute

//     // Nettoyage de l'intervalle lorsque le composant est démonté ou lorsque les dépendances changent
//     return () => clearInterval(intervalId);
//   }, [selectedTime, marcheId]); // Déclenchement de useEffect lorsque selectedTime ou marcheId change

//   // Gestion du clic sur un point du graphique
//   const handlePointClick = (event, elements) => {
//     if (elements.length > 0) {
//       const elementIndex = elements[0].index;
//       const value = data[elementIndex];
//       const fullLabel = fullLabels[elementIndex];
//       // console.log(`Point cliqué - Date et Heure: ${fullLabel}, Valeur: ${value}`);
//     }
//   };

//   // Configuration des données du graphique
//   const chartData = {
//     labels: labels,
//     datasets: [
//       {
//         borderColor: 'gray',
//         borderWidth: 1,
//         radius: 1,
//         data: data,
//       }
//     ]
//   };

//   // Options du graphique
//   const options = {
//     animation: false,
//     interaction: {
//       intersect: false,
//       mode: 'nearest'
//     },
//     plugins: {
//       legend: {
//         display: false
//       },
//       tooltip: {
//         callbacks: {
//           label: (context) => {
//             const index = context.dataIndex;
//             const fullLabel = fullLabels[index];
//             const value = data[index];
//             return `${fullLabel}\n ${value}`;
//           }
//         }
//       }
//     },
//     onClick: handlePointClick,
//     scales: {
//       x: {
//         type: 'category',
//         title: {
//           display: true,
//           text: 'Heures'
//         }
//       },
//       y: {
//         type: 'linear',
//         title: {
//           // display: true,
//         }
//       }
//     }
//   };

//   // Rendu du composant
//   return (
//     <div style={{ width: '100%', height: '100%' }}>
//       <div style={{ position: 'relative', width: '100%', height: '100%' }}>
//         <Line ref={chartRef} data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default ProgressiveLineChart;
// import React, { useState, useEffect, useRef } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
// import axios from 'axios';
// import dayjs from 'dayjs';  // Pour manipuler les dates

// const API_URL = 'http://127.0.0.1:8000/api';

// ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// // Options de temps pour les différents intervalles
// const timeOptions = [
//   { key: '1min', function: 'getDataForLastDay' },
//   { key: '5min', function: 'getDataForLast5Minutes' },
//   { key: '15min', function: 'getDataForLast15Minutes' },
//   { key: '30min', function: 'getDataForLast30Minutes' },
//   { key: '1h', function: 'getDataForLastHour' },
//   { key: '4h', function: 'getDataForLast4Hours' },
//   { key: '1d', function: 'getDataForCurrenday' }, // Utilisation de getDataForCurrenday pour les jours
//   { key: '1w', function: 'getDataForLastWeek' }
// ];

// const ProgressiveLineChart = ({ selectedTime, marcheId }) => {
//   const [data, setData] = useState([]);
//   const [labels, setLabels] = useState([]);
//   const [fullLabels, setFullLabels] = useState([]);
//   const chartRef = useRef();

//   // Fonction pour vérifier si le jour actuel est un week-end
//   const isWeekend = () => {
//     const today = dayjs();
//     return today.day() === 6 || today.day() === 0; // 6 = samedi, 0 = dimanche
//   };

//   // Fonction pour récupérer les données depuis l'API
//   const fetchData = async () => {
//     let routeFunction = isWeekend() ? 'getDataForLastFriday' : timeOptions.find(option => option.key === selectedTime)?.function;

//     try {
//       const response = await axios.get(`${API_URL}/${routeFunction}/${marcheId}`);
//       const data = response.data;

//       if (data && data.values) {
//         const date = data.date;
//         const values = data.values.map(item => item.value);
//         const fullLabels = data.values.map(item => `${date} ${item.heures}`);

//         setData([...values]);
//         setLabels([...data.values.map(item => item.heures)]);
//         setFullLabels([...fullLabels]);
//       } else {
//         setData([]);
//         setLabels([]);
//         setFullLabels([]);
//       }
//     } catch (error) {
//       console.error('Erreur lors de la récupération des données:', error);
//     }
//   };

//   useEffect(() => {
//     fetchData(); // Appel initial

//     const intervalId = setInterval(() => {
//       fetchData();
//     }, 60000); // 60000ms = 1 minute

//     return () => clearInterval(intervalId);
//   }, [selectedTime, marcheId]);

//   const handlePointClick = (event, elements) => {
//     if (elements.length > 0) {
//       const elementIndex = elements[0].index;
//       const value = data[elementIndex];
//       const fullLabel = fullLabels[elementIndex];
//       console.log(`Point cliqué - Date et Heure: ${fullLabel}, Valeur: ${value}`);
//     }
//   };

//   const chartData = {
//     labels: labels,
//     datasets: [
//       {
//         borderColor: 'gray',
//         borderWidth: 1,
//         radius: 1,
//         data: data,
//       }
//     ]
//   };

//   const options = {
//     animation: false,
//     interaction: {
//       intersect: false,
//       mode: 'nearest'
//     },
//     plugins: {
//       legend: {
//         display: false
//       },
//       tooltip: {
//         callbacks: {
//           label: (context) => {
//             const index = context.dataIndex;
//             const fullLabel = fullLabels[index];
//             const value = data[index];
//             return `${fullLabel}\n ${value}`;
//           }
//         }
//       }
//     },
//     onClick: handlePointClick,
//     scales: {
//       x: {
//         type: 'category',
//         title: {
//           display: true,
//           text: 'Heures'
//         }
//       },
//       y: {
//         type: 'linear',
//         title: {
//           display: true,
//           text: 'Valeur'
//         }
//       }
//     }
//   };

//   return (
//     <div style={{ width: '100%', height: '100%' }}>
//       <div style={{ position: 'relative', width: '100%', height: '100%' }}>
//         <Line ref={chartRef} data={chartData} options={options} />
//       </div>
//     </div>
//   );
// };

// export default ProgressiveLineChart;