import React, { useState, useEffect } from "react";
import settingg from '../../images/settingg.png'
import logonavbar from '../../images/logonavbar.png'
import { Link } from "react-router-dom";
import Navbarfooter from "../Navbarfooter";
import Drawer from "../Drawer";
import { Outlet } from "react-router-dom";
import { funcGetTransactionsGroupedByMarket, getArticles, getVideos,getDefis } from '../../Api/authApi.js';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Layout = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };
    const [videos, setVideos] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await getVideos();
                setVideos(response.data);
                // console.log(response)
            } catch (err) {
                setError(err.message);
            }
        };

        fetchVideos();
    }, []);
    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await getVideos();
                setVideos(response.data);
                // console.log(response)
            } catch (err) {
                setError(err.message);
            }
        };

        fetchVideos();
    }, []);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await getVideos();
                const videosWithStatus = response.data.map(video => ({
                    ...video,
                    hasBeenShown: false,  // Ajouter une propriété pour suivre l'affichage
                }));
                setVideos(videosWithStatus);
            } catch (err) {
                console.error('Erreur lors de la récupération des vidéos:', err.message);
            }
        };

        fetchVideos();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();

            setVideos(prevVideos => prevVideos.map(video => {
                const videoDateTime = new Date(`${video.display_date}T${video.display_time}`);

                // Vérifier si l'heure et la minute actuelles correspondent exactement à celles de la vidéo
                if (
                    now.getHours() === videoDateTime.getHours() &&
                    now.getMinutes() === videoDateTime.getMinutes()
                ) {
                    const videoShown = localStorage.getItem(`video_${video.id}`);
                    if (!videoShown) {
                        showToastWithVideo(video);
                        localStorage.setItem(`video_${video.id}`, 'true'); // Marquer la vidéo comme affichée
                        return { ...video, hasBeenShown: true }; // Marquer la vidéo comme affichée dans l'état local aussi
                    }
                }
                return video;
            }));
        }, 1000); // Vérifier toutes les secondes

        return () => clearInterval(interval); // Nettoyage de l'intervalle lors du démontage du composant
    }, [videos]);
    const showToastWithVideo = (video) => {
        toast(
            <div>
                <h4>{video.title}</h4>
                <video width="250" height="120" controls>
                <source src={`https://tradingseries.fr/admin/public/storage/videos/${video.video_path.split('/').pop()}`} type="video/mp4" />
                Votre navigateur ne supporte pas la lecture de vidéos.
                </video>
            </div>,
            {
                autoClose: false,
                draggable: false,
                closeOnClick: false,
                position: "bottom-center", // Remplacez par une chaîne de caractères correcte
            }
        );
    };
    return (

        <div className="relative min-h-screen bg-black flex flex-col">
            <Drawer toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
            <div className="flex-1 lg:mt-24 mt-20 p-1 bg-black cursor-pointer">
                <Outlet />
            </div>
            <div className="block lg:hidden 2xl:hidden xl:hidden md:hidden sm:hidden">
                <Navbarfooter />
            </div>
        </div>
    )
}

export default Layout
// import React, { useState, useEffect } from "react";
// import settingg from '../../images/settingg.png';
// import logonavbar from '../../images/logonavbar.png';
// import { Link } from "react-router-dom";
// import Navbarfooter from "../Navbarfooter";
// import Drawer from "../Drawer";
// import { Outlet } from "react-router-dom";
// import { getVideos } from '../../Api/authApi.js';
// import { toast, ToastContainer } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';

// const Layout = () => {
//     const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//     const toggleDrawer = () => {
//         setIsDrawerOpen(!isDrawerOpen);
//     };
//     const [videos, setVideos] = useState([]);
//     const [error, setError] = useState(null);

//     // Récupérer les vidéos lors du montage du composant
//     useEffect(() => {
//         const fetchVideos = async () => {
//             try {
//                 const response = await getVideos();
//                 const videosWithStatus = response.data.map(video => ({
//                     ...video,
//                     hasBeenShown: false,  // Ajouter une propriété pour suivre l'affichage
//                 }));
//                 setVideos(videosWithStatus);
//             } catch (err) {
//                 setError(err.message);
//             }
//         };

//         fetchVideos();
//     }, []);

//     // Vérifier toutes les minutes pour voir s'il y a une nouvelle vidéo
//     useEffect(() => {
//         const interval = setInterval(() => {
//             const now = new Date();

//             setVideos(prevVideos => prevVideos.map(video => {
//                 const videoDateTime = new Date(`${video.display_date}T${video.display_time}`);

//                 // Vérifier si l'heure et la minute actuelles correspondent à celles de la vidéo
//                 if (
//                     now.getHours() === videoDateTime.getHours() &&
//                     now.getMinutes() === videoDateTime.getMinutes()
//                 ) {
//                     const videoShown = localStorage.getItem(`video_${video.id}`);
//                     if (!videoShown) {
//                         showToastWithVideo(video);
//                         localStorage.setItem(`video_${video.id}`, 'true'); // Marquer la vidéo comme affichée
//                         return { ...video, hasBeenShown: true };
//                     }
//                 }
//                 return video;
//             }));

//             // Nettoyage du localStorage pour les anciennes vidéos
//             Object.keys(localStorage).forEach(key => {
//                 if (key.startsWith("video_")) {
//                     const videoId = key.split("_")[1];
//                     const video = videos.find(v => v.id === parseInt(videoId));
//                     if (video) {
//                         const videoDateTime = new Date(`${video.display_date}T${video.display_time}`);
//                         if (now - videoDateTime > 24 * 60 * 60 * 1000) {
//                             localStorage.removeItem(key);
//                         }
//                     }
//                 }
//             });
//         }, 60000); // Vérifier toutes les minutes

//         return () => clearInterval(interval);
//     }, [videos]);

//     // Afficher une notification avec la vidéo
//     const showToastWithVideo = (video) => {
//         toast(
//             <div>
//                 <h4>{video.title}</h4>
//                 <video width="250" height="120" controls>
//                     <source src={`https://tradingseries.fr/admin/public/storage/videos/${video.video_path.split('/').pop()}`} type="video/mp4" />
//                     Votre navigateur ne supporte pas la lecture de vidéos.
//                 </video>
//             </div>,
//             {
//                 autoClose: false,
//                 draggable: false,
//                 closeOnClick: false,
//                 position: "bottom-center",
//             }
//         );
//     };

//     return (
//         <div className="relative min-h-screen bg-black flex flex-col">
//             <Drawer toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
//             <div className="flex-1 lg:mt-24 mt-20 p-1 bg-black cursor-pointer">
//                 <Outlet />
//             </div>
//             <div className="block lg:hidden 2xl:hidden xl:hidden md:hidden sm:hidden">
//                 <Navbarfooter />
//             </div>
//             <ToastContainer />
//         </div>
//     );
// };

// export default Layout;

// import React, { useState, useEffect } from "react";
// import settingg from '../../images/settingg.png';
// import logonavbar from '../../images/logonavbar.png';
// import { Link } from "react-router-dom";
// import Navbarfooter from "../Navbarfooter";
// import Drawer from "../Drawer";
// import { Outlet } from "react-router-dom";
// import { getVideos } from '../../Api/authApi.js';
// import { toast, ToastContainer } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';

// const Layout = () => {
 
//     const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//     const [videos, setVideos] = useState([]);
//     const [error, setError] = useState(null);

//     const toggleDrawer = () => {
//         setIsDrawerOpen(!isDrawerOpen);
//     };

//     useEffect(() => {
//         const fetchVideos = async () => {
//             try {
//                 const response = await getVideos();
//                 const videosWithStatus = response.data.map(video => ({
//                     ...video,
//                     hasBeenShown: false,
//                 }));
//                 setVideos(videosWithStatus);
//             } catch (err) {
//                 setError(err.message);
//             }
//         };

//         fetchVideos();
//     }, []);

//     useEffect(() => {
//         const interval = setInterval(() => {
//             const now = new Date();

//             setVideos(prevVideos => prevVideos.map(video => {
//                 const videoDateTime = new Date(`${video.display_date}T${video.display_time}`);

//                 if (
//                     now.getHours() === videoDateTime.getHours() &&
//                     now.getMinutes() === videoDateTime.getMinutes() &&
//                     !video.hasBeenShown
//                 ) {
//                     const videoShown = localStorage.getItem(`video_${video.id}`);
//                     if (!videoShown) {
//                         showToastWithVideo(video);
//                         localStorage.setItem(`video_${video.id}`, 'true');
//                         return { ...video, hasBeenShown: true };
//                     }
//                 }
//                 return video;
//             }));
//         }, 1000);

//         return () => clearInterval(interval);
//     }, [videos]);

//     const showToastWithVideo = (video) => {
//         toast(
//             <div>
//                 <h4>{video.title}</h4>
//                 <video width="300" height="120" controls>
//                     <source src={`https://tradingseries.fr/admin/public/storage/videos/${video.video_path.split('/').pop()}`} type="video/mp4" />
//                     Votre navigateur ne supporte pas la lecture de vidéos.
//                 </video>
//             </div>,
//             {
//                 autoClose: false,
//                 draggable: false,
//                 closeOnClick: false,
//                 position: "bottom-center",
//             }
//         );
//     };

//     return (
//         <div className="relative min-h-screen bg-black flex flex-col">
//         <Drawer toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
//         <div className="flex-1 lg:mt-24 mt-20 p-1 bg-black cursor-pointer">
//                 <Outlet />
//             </div>
//             <div className="block lg:hidden 2xl:hidden xl:hidden md:hidden sm:hidden">
//                 <Navbarfooter />
//             </div>
//             <ToastContainer />
//         </div>
//     );
// };

// export default Layout;
