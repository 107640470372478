/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import CustomHeader from '../../components/customheader'
import CustomFooter from '../../components/customfooter'
import CustomInput from '../../components/custominput'
import key from '../../images/key.png'
import clock from '../../images/clock.png'
import CustomButton from '../../components/custombutton'
import next from '../../images/next.png'
import { Link } from 'react-router-dom';
import ToDoListModal from '../../components/ToDoListModal';
const RecapLeague = () => {

  return (
    <section className=' bg-black text-white relative h-[100vh]'>
      <Link to='/ligue' className='absolute left '>
        <img class={`w-auto h-auto pl-8 pt-9`} src={next} alt="logotrading" />
      </Link>
      <div className='bg-black text-white flex justify-center'>

        <div className='flex items-center flex-col space-y-3 justify-center w-[100%] sm:w-[80%]  md:w-[80%]   lg:w-[80%]  xl:w-[70%]  2xl:w-[60%] '>
          <CustomHeader width="w-[45%]" />
          <div className='items-center flex justify-center flex-col space-y-7  w-[90%] sm:w-[100%]  md:w-[80%]   lg:w-[80%]  xl:w-[70%]  2xl:w-[70%] '>

            <div className='flex items-center flex-col space-y-3 justify-center'>
              <p className=' max-w-auto  mx-auto text-center md:text-[19px] sm:text-[19px] lg:text-[19px]  xl:text-[23px]  2xl:text-[23px] items-center text-[14px]  font-semibold  '>"Récapitulatif de "Nom de la Ligue"</p>

            </div>

            <div className='flex items-center flex-col space-y-3 justify-center w-[100%] sm:w-[70%]  md:w-[80%]   lg:w-[70%]  xl:w-[70%]  2xl:w-[70%]'>
              <div className='overflow-y-auto w-full max-h-[33vh]'>


                {[...Array(3)].map((_, index) => (
                  <div key={index} className='flex flex-col w-[100%] mb-3'>
                    <h1 className='text-center flex justify-center'>- Equipe {index + 1} -</h1>
                    <div className='flex flex-col items-center gap-3 w-[100%]'>
                      <div className='flex flex-row justify-between space-x-4 w-[100%]'>
                        <button className="border rounded-md font-bold w-[50%] text-white h-9 text-center items-center flex justify-center py-2">
                          User 1
                        </button>
                        <button className="border rounded-md font-bold w-[50%] text-white h-9 text-center items-center flex justify-center py-2">
                          User 2
                        </button>
                      </div>
                      <div className='flex flex-row justify-between space-x-4 w-[100%]'>
                        <button className="border rounded-md font-bold w-[50%] text-white h-9 text-center items-center flex justify-center py-2">
                          User 3
                        </button>
                        <button className="border rounded-md font-bold w-[50%] text-white h-9 text-center items-center flex justify-center py-2">
                          User 4
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='flex flex-col justify-center items-center w-[100%] space-y-5 mt-6'>
                <button className="border rounded-md font-bold w-[70%] text-white h-10 text-center items-center flex justify-center py-2 gap-4">
                  <img className='w-10 h-10 ' src={clock} alt="logotrading" />

                  Départ de la partie

                </button>
                <button className="border rounded-md font-bold w-[70%] text-black bg-white h-10 text-center items-center flex justify-center py-2">

                  Démarrer une partie
                </button>
              </div>
            </div>



            {/* <button>Ajouter un texte</button>
            <ToDoListModal
              isOpen={modalIsOpen}
              onClose={closeModal}
              onAdd={handleAdd}
            />
            <div>
              <h2>Liste des textes</h2>
              <ul>
                {texts.map((text, index) => (
                  <li key={index}>{text}</li>
                ))}
              </ul>
            </div> */}




          </div>


        </div>

      </div>

    </section >

  )
}

export default RecapLeague