import React from 'react'
import { Link } from "react-router-dom";
const Navbarfooter = ({ style }) => {
    return (
        <footer className="fixed bottom-0 left-0 w-full bg-black text-white p-2">
            <div className="flex w-full justify-between  gap-2 items-center  ">
                <Link to="/accueil" className="hover:underline flex flex-col justify-center text-gray-400 items-center text-[10px]"><span>
                    <svg class="w-6 h-6  text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6 2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2 6-6Z" clip-rule="evenodd" />
                    </svg>
                </span>Accueil</Link>
                <Link to='/portefeuille' className="hover:underline flex flex-col justify-center items-center text-gray-400 text-[10px]"><span>
                    <svg class="w-6 h-6 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8H5m12 0a1 1 0 0 1 1 1v2.6M17 8l-4-4M5 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.6M5 8l4-4 4 4m6 4h-4a2 2 0 1 0 0 4h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z" />
                    </svg>

                </span>Portefeuille</Link>
                <Link to="/portefeuille_menu" className="hover:underline flex flex-col justify-center items-center text-[10px] text-gray-400">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(156 163 175)" class="bi bi-bar-chart" viewBox="0 0 16 16">
                        <path d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z" />
                    </svg>
                </span>Marchés</Link>
                <Link to="/classement" className="hover:underline flex flex-col justify-center items-center text-[10px] text-gray-400"><span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(156 163 175)" class="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                    </svg>
                </span>Classement</Link>
                <Link to="/profil" className="hover:underline flex flex-col justify-center items-center text-[10px] text-gray-400"><span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(156 163 175)" class="bi bi-person-fill" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                    </svg>
                </span>Profil</Link>
                <Link to="/" className="hover:underline flex flex-col justify-center items-center text-[10px] text-gray-400"><span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(156 163 175)" class="bi bi-person-fill" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                    </svg>
                </span>Déconnexion</Link>


            </div>
        </footer>
    )
}
export default Navbarfooter
