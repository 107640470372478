import React from 'react'

const CustomFooter = ({styelefooter}) => {
    return (
        <>
            <p className={`mt-4 max-w-auto   text-center  justify-center flex  md:text-[19px] sm:text-[19px] lg:text-[19px]  xl:text-[23px]  2xl:text-[23px] items-center text-[12px] font-IMFellDW ${styelefooter}`}>"L'argent utilisé dans ce jeu est vitruel, tâche à ne pas prendre les mêmes risques dans la réalité"</p>
        </>
    )
}

export default CustomFooter