// import React, { useEffect, useRef } from 'react';
// import $ from 'jquery'; // Import de jQuery

// const StockChart = () => {
//     const stockChartRef = useRef(null); // Utilisation d'un ref pour accéder au stockChart

//   useEffect(() => {
//     var dps1 = [];  // Les données du graphique principal
    
//     // Initialisation du StockChart sans le panneau de navigation
//     var stockChart = new window.CanvasJS.StockChart("chartContainer", {
//       theme: "light2", // Choisissez entre "light1", "light2", "dark1", "dark2"
//       exportEnabled: false, // Désactiver l'exportation si nécessaire
//       navigator: {
        
//         enabled: false, //Change it to true
        
//       },   
//       rangeSelector: {
        
//         inputFields: {
//             enabled: false,
            
                   
//         },
//         position: "bottom",

  
//       },
//       inputFields: {
//         enabled: false // Supprimer les input fields
//       },
    
//       backgroundColor: "black",

//         subtitles: [{
//         text: "Bitcoin Price (in USD)"
//       }],
    
//       charts: [{
//         axisX: {
//           crosshair: {
//             enabled: false,
//             snapToDataPoint: true
//           },
//           labelFontColor: "#5a5a5a", // Couleur des étiquettes de l'axe X
//           lineColor: "black", // Couleur de la ligne de l'axe X
//           tickColor: "#yellow", // Couleur des ticks de l'axe X
//         },
//         axisY: {
//             prefix: "$",
//             labelFontColor: "#5a5a5a", // Couleur des étiquettes de l'axe Y
//             gridThickness: 1, // Épaisseur des lignes de la grille
//             gridColor: "black", // Couleur de la grille
//             tickLength: 0, // Longueur des ticks
//             lineThickness: 0, // Supprime la ligne de l'axe Y
//             lineColor: "transparent", // Rend la ligne invisible
//           },
//         data: [{
//           type: "candlestick",
//           yValueFormatString: "$#,###.##",
//           dataPoints: dps1,
//           risingColor: "#00ff00",  // Vert pour les chandeliers haussiers
//           fallingColor: "#ff0000",  // Rouge pour les chandeliers baissiers
//         }]
//       }]
//       // Ne pas inclure la section `navigator`
//     });

//     // Chargement des données JSON pour le graphique en chandeliers
//     $.getJSON("https://canvasjs.com/data/docs/btcusd2018.json", function (data) {
//       for (var i = 0; i < data.length; i++) {
//         dps1.push({ 
//           x: new Date(data[i].date), 
//           y: [
//             Number(data[i].open), 
//             Number(data[i].high), 
//             Number(data[i].low), 
//             Number(data[i].close)
//           ] 
//         });
//       }
//       stockChart.render(); // Afficher le graphique
//     });

//   }, []); // useEffect pour exécuter le code au montage du composant
//   const handleRangeChange = (range, rangeType) => {
//     const chart = stockChartRef.current;

//     // Vérifiez si le graphique est bien initialisé
//     if (chart) {
//       const minDate = new Date(new Date().setMonth(new Date().getMonth() - range));
//       const maxDate = new Date();

//       chart.navigator.slider.minimum = minDate;
//       chart.navigator.slider.maximum = maxDate;
//       chart.render();
//     } else {
//       console.error("Le stockChart n'est pas encore initialisé.");
//     }
//   };

//   return (
//     <div>
//       <div id="chartContainer" style={{ height: 600, width:'100%' }}></div>
//       <div style={{ textAlign: "center", marginTop: "10px" }}>
//         <button style={{ backgroundColor: "#4CAF50", color: "white", padding: "5px 10px", margin: "5px", borderRadius: "5px", border: "none" }}
//           onClick={() => handleRangeChange(1, "month")}>1M</button>
//         <button style={{ backgroundColor: "#4CAF50", color: "white", padding: "5px 10px", margin: "5px", borderRadius: "5px", border: "none" }}
//           onClick={() => handleRangeChange(3, "month")}>3M</button>
//         <button style={{ backgroundColor: "#4CAF50", color: "white", padding: "5px 10px", margin: "5px", borderRadius: "5px", border: "none" }}
//           onClick={() => handleRangeChange(6, "month")}>6M</button>
//         <button style={{ backgroundColor: "#4CAF50", color: "white", padding: "5px 10px", margin: "5px", borderRadius: "5px", border: "none" }}
//           onClick={() => handleRangeChange(1, "year")}>1Y</button>
//         <button style={{ backgroundColor: "#4CAF50", color: "white", padding: "5px 10px", margin: "5px", borderRadius: "5px", border: "none" }}
//           onClick={() => handleRangeChange(5, "year")}>5Y</button>
//         <button style={{ backgroundColor: "#4CAF50", color: "white", padding: "5px 10px", margin: "5px", borderRadius: "5px", border: "none" }}
//           onClick={() => handleRangeChange(10, "year")}>All</button>
//       </div>
//     </div>
//   );
// }

// export default StockChart;




import React, { useEffect, useState } from 'react';

const StockChart = ({ marcheId,userId }) => {
  const [dps1, setDps1] = useState([]); // Stocker les données récupérées
  const [chart, setChart] = useState(null); // Stocker le graphique
  const [loading, setLoading] = useState(true); // Loader pour indiquer le chargement
  const [selectedPeriod, setSelectedPeriod] = useState('1min'); // Période sélectionnée

  useEffect(() => {
    // Initialiser le graphique lors du premier rendu
    const stockChart = new window.CanvasJS.StockChart("chartContainer", {
      theme: "light2",
      exportEnabled: false,
      navigator: { enabled: false },
      rangeSelector: { inputFields: { enabled: false }, enabled: false },
      backgroundColor: "black",
      charts: [{
        axisX: { crosshair: { enabled: false, snapToDataPoint: true, }, labelFontColor: "#5a5a5a", lineColor: "black", tickColor: "#yellow" },
        axisY: { prefix: "", labelFontColor: "#5a5a5a", gridThickness: 1, gridColor: "black", tickLength: 0, lineThickness: 0, lineColor: "transparent" ,labelFormatter: function(e) {
          return "";
      }},
        data: [{
          type: "candlestick",
          yValueFormatString: "$#,###.##",
          dataPoints: dps1,
          risingColor: "#00ff00",
          fallingColor: "#ff0000",
        }]
      }]
    });

    setChart(stockChart);
    stockChart.render(); // Afficher le graphique

    // Charger les données par défaut et toutes les 60 secondes
    fetchData('1min'); // Charger les données par défaut pour 30 minutes
    const interval = setInterval(() => {
      fetchData(selectedPeriod); // Re-fetch les données toutes les 60 secondes
    }, 60000); // 60 secondes

    return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage
  }, []);
  // const API_URL = 'https://tradingseries.fr/admin/public/api';
  const API_URL = 'http://localhost:8000/api/';

  // Fonction pour récupérer les données de l'API
  const fetchData = async (period) => {
    setLoading(true); // Afficher le loader
    let url = "";
    // switch (period) {
    //   case '1min':
    //     url = `http://localhost:8000/api/getDataForCurrentAndLastDay/${marcheId}/${userId}`;
    //     break;
    //   case '10min':
    //     url = `http://localhost:8000/api/getDataForCurrentAndLast10Minutes/${marcheId}/${userId}`;
    //     break;
    //   case '30min':
    //     url = `http://localhost:8000/api/getDataForCurrentAndLast30Minutes/${marcheId}/${userId}`;
    //     break;
    //   case 'hour':
    //     url = `http://localhost:8000/api/getDataForCurrentAndLastHour/${marcheId}/${userId}`;
    //     break;
    //   case 'day':
    //     url = `http://localhost:8000/api/getDataForCurrentAndLastDay/${marcheId}/${userId}`;
    //     break;
    //   default:
    //     url = `http://localhost:8000/api/getDataForCurrentAndLast30Minutes/${marcheId}/${userId}`;
    // }
    switch (period) {
      case '1min':
        url = `https://tradingseries.fr/admin/public/api/getDataForCurrentAndLastDay/{marcheId}/${userId}`;
        break;
      case '10min':
        url = `https://tradingseries.fr/admin/public/api/getDataForCurrentAndLast10Minutes/{marcheId}/${userId}`;
        break;
      case '30min':
        url = `https://tradingseries.fr/admin/public/api/getDataForCurrentAndLast30Minutes/{marcheId}/${userId}`;
        break;
      case 'hour':
        url = `https://tradingseries.fr/admin/public/api/getDataForCurrentAndLastHour/{marcheId}/${userId}`;
        break;
      case 'day':
        url = `https://tradingseries.fr/admin/public/api/getDataForCurrentAndLastDay/{marcheId}/${userId}`;
        break;
      default:
        url = `https://tradingseries.fr/admin/public/api/getDataForCurrentAndLast30Minutes/{marcheId}/${userId}`;
    }

    try {
      const response = await fetch(url);
      const data = await response.json();

      const dataPoints = data.values.map(item => {
        let high = Number(item.high);
        let low = Number(item.low);

        // Si la différence entre high et low est trop faible, ajouter une marge artificielle
        const difference = high - low;
        const minDifference = 0.2; // Seuil minimum pour la différence

        if (difference < minDifference) {
          const margin = (minDifference - difference) / 2;
          high += margin;
          low -= margin;
        }

        return {
          x: new Date(`${data.date} ${item.minute}`),
          y: [Number(item.open), high, low, Number(item.close)],
          color: item.color
        };
      });

      setDps1(dataPoints); // Mettre à jour les data points
      updateChart(dataPoints); // Mettre à jour rapidement le graphique
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setLoading(false); // Cacher le loader
    }
  };

  // Fonction pour mettre à jour les données du graphique
  const updateChart = (dataPoints) => {
    if (chart) {
      chart.options.charts[0].data[0].dataPoints = dataPoints; // Mettre à jour les points de données
      chart.render(); // Réafficher le graphique avec les nouvelles données
    }
  };

  // Gestion de la sélection de la période
  const handlePeriodSelect = (period) => {
    setSelectedPeriod(period); // Mettre à jour la période sélectionnée
    fetchData(period); // Charger les nouvelles données pour la période sélectionnée
  };

  return (
    <div>
      {loading && <p>Chargement des données...</p>} {/* Afficher un loader pendant le chargement */}
      <div id="chartContainer" style={{ height: 400, width: '100%' }}></div>
      <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
        <button
          style={selectedPeriod === '1m' ? selectedButtonStyle : buttonStyle}
          onClick={() => handlePeriodSelect('1m')}
        >
          1M
        </button>
        <button
          style={selectedPeriod === '10min' ? selectedButtonStyle : buttonStyle}
          onClick={() => handlePeriodSelect('10min')}
        >
          10M
        </button>
        <button
          style={selectedPeriod === '30min' ? selectedButtonStyle : buttonStyle}
          onClick={() => handlePeriodSelect('30min')}
        >
          30M
        </button>
        <button
          style={selectedPeriod === 'hour' ? selectedButtonStyle : buttonStyle}
          onClick={() => handlePeriodSelect('hour')}
        >
          1h
        </button>
        <button
          style={selectedPeriod === 'day' ? selectedButtonStyle : buttonStyle}
          onClick={() => handlePeriodSelect('day')}
        >
          1d
        </button>
      </div>
    </div>
  );
};

// Styles pour les boutons
const buttonStyle = {
  padding: '10px',
  backgroundColor: '#4CAF50',
  color: 'white',
  borderRadius: '5px',
  margin: '5px',
  border: 'none',
};

const selectedButtonStyle = {
  ...buttonStyle,
  backgroundColor: 'red', // Couleur différente pour le bouton sélectionné
};

export default StockChart;
