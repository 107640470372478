/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';


import francelogo from '../../images/drapeaux/francedrapeau.png'
import USddrapeau from '../../images/drapeaux/USddrapeau.png'
import RoyaumeUnidrapeau from '../../images/drapeaux/RoyaumeUnidrapeau.png'
import Drawer from "../../components/Drawer";
import { Link } from "react-router-dom";
import Navbarfooter from "../../components/Navbarfooter";
import StackedBarChart from "../../components/chartgraphique/BarChart";
import { getMarches, getDataForCurrentMinutes, postAchat, getImagesByMarketType, getMarchesByType, getAvailableCountriesByType } from '../../Api/authApi.js';
import { useParams } from 'react-router-dom';

const PorteFeuilleBitcoin = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedButton, setSelectedButton] = useState('France');
    const [affichetitle, setaffichetitle] = useState('');
    const [countries, setCountries] = useState([]);
    const [markets, setMarkets] = useState({});
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [marketData, setMarketData] = useState([]);
    const { type } = useParams(); // Récupérer le paramètre "type" depuis l'URL

    useEffect(() => {
        const fetchMarketType = async () => {
            try {
                const response = await getAvailableCountriesByType(type);
                setCountries(response.countries);
                setMarkets(response.markets);

                // Afficher par défaut les données pour le premier pays
                if (response.countries.length > 0) {
                    const firstCountry = response.countries[0];
                    setSelectedCountry(firstCountry);

                    // Récupérer les marchés pour le premier pays
                    const countryMarkets = response.markets[firstCountry] || [];
                    if (countryMarkets.length > 0) {
                        const firstMarket = countryMarkets[0];
                        const data = await getDataForCurrentMinutes(firstMarket.id);
                        setMarketData([{ market: firstMarket, data }]);

                        setaffichetitle(firstMarket.titre); // Afficher le titre du marché
                    }
                }
            } catch (err) {
                console.log(err.message);
            }
        };

        fetchMarketType();
    }, [type]);

    const handleCountryClick = async (country) => {
        setSelectedCountry(country);
        fetchMarketsByCountry(country);
    };

    const fetchMarketsByCountry = async (country) => {
        try {
            const countryMarkets = markets[country] || [];

            console.log(`Marchés pour ${country} :`, countryMarkets);

            // Effacer les données de marché actuelles
            setMarketData([]);

            // Récupérer les données pour chaque marché dans ce pays
            const marketDataPromises = countryMarkets.map(async (market) => {
                const data = await getDataForCurrentMinutes(market.id);
                return { market, data };
            });

            // Attendre que toutes les données de marché soient récupérées
            const allMarketData = await Promise.all(marketDataPromises);

            if (allMarketData.length > 0) {
                // Définir les données du premier marché comme valeur par défaut
                setMarketData([allMarketData[0]]);
                setaffichetitle(allMarketData[0].market.titre); // Afficher le titre du marché

            }

            console.log(`Data pour les marchés de ${country} :`, allMarketData);
        } catch (error) {
            console.error("Erreur lors de la récupération des données de marché :", error.message);
        }
    };
    const formatDifference = (difference) => {
        if (difference > 0) {
            return `+${difference.toFixed(2)}`; // Ajouter le signe + pour les valeurs positives
        } else if (difference < 0) {
            return `${difference.toFixed(2)}`; // Le signe - est déjà inclus pour les valeurs négatives
        } else {
            return difference.toFixed(2); // Pour les valeurs nulles
        }
    };

    const getChangeColor = (difference) => {
        if (difference > 0) {
            return 'text-green-500'; // Couleur pour les valeurs positives
        } else if (difference < 0) {
            return 'text-red-500'; // Couleur pour les valeurs négatives
        } else {
            return 'text-gray-500'; // Couleur pour les valeurs nulles ou égales à zéro
        }
    };

    return (
        <div className="bg-black w-full relative overflow-auto h-[100%] lg:pb-auto pb-12">

            <div className="grid  lg:grid-cols-1  md:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 grid-cols-1 sm:grid-cols-1 flex-col items-center justify-center  gap-7 sm:px-10 md:px-16">


                <div className="lg:w-[98%] w-[96%] lg:p-4 p-2 lg:border md:border  sm:border rounded-lg shadow bg-balck text-white flex    mx-auto flex-col ">

                    <div className="flex flex-row">
                        {countries.map(country => (
                            <button
                                key={country}
                                onClick={() => handleCountryClick(country)}

                                className={`border rounded-xs w-1/3 justify-center items-center cursor-pointer ${selectedCountry === country ? 'bg-white text-black' : 'bg-black text-gray-300'} font-normal lg:text-[15px] lg:font-medium text-[13px] text-center px-4 py-1.5 2xl:p-2 xl:p-2 lg:p-2 md:p-2`}
                            >
                                {country}
                            </button>
                        ))}
                    </div>
                    <div className="flex  justify-between mx-3 py-3">
                        <p className='p-2'>{ }</p>
                        <button className={` hover:bg-white hover:text-black  border rounded-xs  text-gray-300 w-auto px-4   justify-center items-center   bg-black font-normal text-[13px] text-center flex  gap-4  `}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel" viewBox="0 0 16 16">
                                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                                </svg>
                            </span>
                            <span>Trier par
                            </span>
                        </button>
                    </div>
                    {selectedCountry && marketData.length > 0 ? (
                        <div >
                            <h3> {selectedCountry} </h3>
                            {marketData.map(({ market, data }, index) => (
                                <div key={index} className="flex justify-between px-3 py-3 border-b border-gray-500">
                                    <div className="flex gap-2 items-center">
                                        <div className="relative">
                                            {index <= 5 && (
                                                <span className="absolute -top-2 -left-1">
                                                    <svg className="w-5 h-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                                                    </svg>
                                                </span>
                                            )}
                                            <img src={`https://tradingseries.fr/admin/public${market.icon}`} className="w-14 h-14 rounded-full" alt={market.titre} />
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-[14px] lg:text-md font-semibold">{market.titre}</p>
                                            <p className="text-[12px] lg:text-md font-semibold flex gap-2 items-center ">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="red" className="bi bi-clock-fill" viewBox="0 0 16 16">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                                    </svg>
                                                </span>{data.hour}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center flex-col font-medium">
                                        <p className="text-[12px] lg:text-md ">
                                            {parseFloat(data.value).toFixed(3)}
                                        </p>
                                        <p className={`text-[11px] lg:text-md ${getChangeColor(data.difference)}`}>
                                            {formatDifference(data.difference)}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="text-center py-5">
                            <p className="text-red-500">Pas de données disponibles</p>
                        </div>
                    )}

                </div>

            </div>
        </div>

    )
};
export default PorteFeuilleBitcoin;
